import styled from 'styled-components/macro';
import { Greeting, Headline } from 'styles/typography';
import theme from 'styles/theme';

const { space, down, breakpoints } = theme;

export const StyledHero = styled.header.attrs((props) => ({
  pt: space[props.pt] || space[11],
  pb: space[props.pb] || 'var(--space-xxl)',
}))`
  padding: var(--space-xxl) 0;
  padding-top: ${(props) => props.pt};
  padding-bottom: ${(props) => props.pb};
  margin-top: ${space[9]};

  ${Greeting} {
    margin-bottom: var(--space-sm);
  }

  ${Headline} {
    font-weight: var(--font-weight-medium);
    max-width: var(--wrapper-xl);
  }

  a {
    margin-top: 2rem;
  }

  ${down(breakpoints.sm)} {
    padding: var(--space-xl) 0;
    padding-bottom: ${space[10]};
  }

  ${down(breakpoints.xs)} {
    padding: var(--space-xl) 0;
    padding-bottom: ${space[10]};
    margin-top: ${space[10]};
  }
`;

// position: relative;

// &::after {
//   opacity: 0.5;
//   background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
//   background-size: 400% 400%;
//   animation: gradient 8s ease infinite;
//   content: '';
//   display: block;
//   width: 20rem;
//   height: 20rem;
//   border-radius: 50%;
//   position: absolute;
//   top: 50%;
//   right: 0%;
//   filter: blur(5rem);
//   transform: translateY(-50%);
//   z-index: -1;
// }

// @keyframes gradient {
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 100% 50%;
//   }
//   100% {
//     background-position: 0% 50%;
//   }
// }
