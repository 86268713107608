import React from 'react';
import { IoLockClosed } from 'react-icons/io5';
import { StyledFeaturedItem } from './FeaturedItem.legacy.styled';
import { Image } from 'components/image';
import { Headline, Paragraph, Allcaps } from 'styles/typography';
import { CallToAction } from 'components/buttons';

const FeaturedItem = ({ work }) => {
  const {
    title,
    name,
    description,
    category,
    isFeatured,
    isExternal,
    isProtected,
    link,
  } = work;

  return (
    <StyledFeaturedItem name={name} full={isFeatured}>
      {isProtected && <IoLockClosed className='lock-icon' />}
      <div className='content'>
        <Allcaps>{category}</Allcaps>
        <Headline as='h3' level={3}>
          {title}
        </Headline>
        <Paragraph level={1}>{description}</Paragraph>
        <CallToAction
          linkTo={
            !isExternal
              ? `/${name}`
              : {
                  pathname: `${link}`,
                }
          }
          target={isProtected ? '_blank' : ''}
          title={`${name}`}
          name={name}
          type='tertiary'
          isExternal={isExternal}
        >
          View more
        </CallToAction>
      </div>
      <Image
        className='image'
        path='home'
        name={name}
        format='png'
        sizes={[640, 1280, 1920]}
        alt={`${name} mockup`}
      />
    </StyledFeaturedItem>
  );
};

export default FeaturedItem;
