import { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import InpageNavContext from 'context/inpage-nav/InpageNavContext';

const useInpageNav = (id) => {
  const [ref, inView] = useInView();
  const { setActive } = useContext(InpageNavContext);

  useEffect(() => {
    if (inView) {
      setActive(id);
    }
    // eslint-disable-next-line
  }, [inView, id]);

  return ref;
};

export default useInpageNav;
