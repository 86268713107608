import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import StyledInpageNav from './InpageNav.styled';
import InpageNavLink from './InpageNavLink';
import InpageNavContext from 'context/inpage-nav/InpageNavContext';
import inpageNavLinks from 'data/inpageNavLinks';

const InpageNav = () => {
  const { clearActive, isVisible, toggleInpageNav } =
    useContext(InpageNavContext);
  const location = useLocation().pathname.slice(1);

  useEffect(
    () => clearActive,
    // eslint-disable-next-line
    []
  );

  return (
    <StyledInpageNav
      onMouseEnter={toggleInpageNav}
      onMouseLeave={toggleInpageNav}
    >
      <ul className={isVisible ? 'expanded' : null}>
        {inpageNavLinks[location].map((linkItem) => {
          const { name, id } = linkItem;

          return <InpageNavLink key={id} name={name} id={id} />;
        })}
      </ul>
    </StyledInpageNav>
  );
};

export default InpageNav;
