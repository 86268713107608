import React from 'react';
import styled from 'styled-components/macro';
import Quote from 'patterns/Quote';
import { Card } from 'styles/card';
import { Headline, Subtitle } from 'styles/typography';
import { Grid } from 'styles/layout';

const StyledInsight = styled(Card)`
  background: var(--color-white);
  border: 1px solid var(--color-grey-200);

  & > div:not(:last-of-type) {
    margin-bottom: var(--space-md);
  }

  ${Grid} & {
    height: 100%;
  }

  ${Headline} {
    margin-bottom: var(--space-md);
    font-weight: var(--font-weight-regular);
    color: var(--color-grey-500);
  }

  ${Subtitle} {
    margin-bottom: var(--space-sm);
    line-height: var(--line-height-lg);
    color: var(--color-grey-700);
  }
`;

const Insight = ({ keyword, content }) => {
  return (
    <StyledInsight radius='md' padding='var(--space-lg) var(--space-md)'>
      <Headline level={6} as='div'>
        {keyword.charAt(0).toUpperCase() + keyword.slice(1)}
      </Headline>
      {content.map((item, idx) => (
        <div key={idx}>
          <Subtitle level={2} as='h5'>
            {item.insight}
          </Subtitle>
          {item.quotes.map(({ quote, quotedBy }, idx) => (
            <Quote key={idx} quote={quote} quotedBy={quotedBy} />
          ))}
        </div>
      ))}
    </StyledInsight>
  );
};

export default Insight;
