import styled from 'styled-components/macro';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: var(--z-index-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;

  svg {
    position: absolute;
    top: 2rem;
    right: 3rem;
    width: 3.5rem;
    height: 3.5rem;
    color: #fff;
    cursor: pointer;
  }

  .modal-content {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    background: #fff;
    margin: var(--page-margin);
    padding: var(--space-lg);
    padding-bottom: var(--space-md);
    cursor: default;
  }

  ${down(breakpoints.lg)} {
    svg {
      right: 2rem;
    }

    .modal-content {
      padding: var(--space-md);
      padding-bottom: var(--space-sm);
    }
  }

  ${down(breakpoints.md)} {
    svg {
      right: var(--page-margin);
    }

    .modal-content {
      padding: var(--space-sm);
      padding-bottom: var(--space-xs);
    }
  }
`;
