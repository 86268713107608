import React from 'react';
import {
  FaPaperPlane,
  FaLinkedinIn,
  FaGithub,
  FaArrowUp,
} from 'react-icons/fa';
import { StyledFooter } from './Footer.styled';
import { StyledLink } from 'styles/link';
import { Paragraph } from 'styles/typography';
import { Button } from 'components/buttons';

const Footer = () => {
  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

  return (
    <StyledFooter as='footer' justifyContent='space-between'>
      <ul>
        <li>
          <StyledLink as='a' href='mailto:ha.yuvin@gmail.com'>
            <FaPaperPlane />
          </StyledLink>
        </li>
        <li>
          <StyledLink
            to={{ pathname: 'https://www.linkedin.com/in/yuvinha' }}
            target='_blank'
          >
            <FaLinkedinIn />
          </StyledLink>
        </li>
        <li>
          <StyledLink
            to={{ pathname: 'https://github.com/yuvinha' }}
            target='_blank'
          >
            <FaGithub />
          </StyledLink>
        </li>
      </ul>
      <Paragraph level={2}>
        &copy; {new Date().getFullYear()}. Yuvin Ha. All rights reserved.
      </Paragraph>
      <Button type='tertiary' color='var(--color-white)' onClick={scrollToTop}>
        Top <FaArrowUp />
      </Button>
    </StyledFooter>
  );
};

export default Footer;
