import styled from 'styled-components/macro';
import { Subtitle, Paragraph, Caption } from 'styles/typography';

export const StyledReflectionItem = styled.div`
  position: relative;
  left: 1.2rem;

  ${Subtitle} {
    position: relative;
    margin-bottom: var(--space-xxs);
    color: var(--color-grey-900);
    font-weight: var(--font-weight-medium);

    &::before {
      content: '\\2022';
      display: inline-block;
      position: absolute;
      left: -1.2rem;
    }
  }

  ${Paragraph} {
    color: var(--color-grey-700);
  }

  ${Caption} {
    display: inline-block;
    color: var(--color-grey-500);
    &:not(:last-of-type) {
      margin-right: var(--space-sm);
    }
  }

  margin-bottom: var(--space-md);
`;
