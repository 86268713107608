import React from 'react';
import styled from 'styled-components/macro';
import { Grid } from 'styles/layout';

const StyledQuote = styled(Grid)`
  align-items: center;
  color: var(--color-grey-500);
  margin-left: var(--space-xxs);

  span {
    font-style: italic;
  }

  &::before {
    content: '';
    display: block;
    width: 2px;
    height: 90%;
    background: var(--color-grey-300);
  }

  &:not(:last-of-type) {
    margin-bottom: var(--space-xs);
  }
`;

const Quote = ({ quote, quotedBy }) => {
  return (
    <StyledQuote col={['min-content', '1fr']} gap='sm'>
      <p>
        {`"${quote}"`} {quotedBy && <span>&mdash; {quotedBy}</span>}
      </p>
    </StyledQuote>
  );
};

export default Quote;
