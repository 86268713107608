import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';

export const Headline = styled.h1`
  font-family: var(--font-family-title);
  font-size: ${(props) => `var(--font-size-h${props.level})`};
  line-height: var(--line-height-sm);

  ${(props) => {
    switch (props.level) {
      case 1:
        return h1;
      case 2:
        return h2;
      case 3:
        return h3;
      case 4:
        return h4;
      case 5:
        return h5;
      case 6:
        return h6;
      default:
        return;
    }
  }}
`;

Headline.propTypes = {
  level: PropTypes.number.isRequired,
};

const h1 = css`
  font-weight: var(--font-weight-light);
  letter-spacing: var(--letter-spacing-sm);
`;
const h2 = css`
  font-weight: var(--font-weight-regular);
  letter-spacing: normal;
`;
const h3 = css`
  font-weight: var(--font-weight-medium);
  letter-spacing: normal;
`;
const h4 = css`
  font-weight: var(--font-weight-medium);
  letter-spacing: normal;
`;
const h5 = css`
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-md);
`;
const h6 = css`
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-sm);
  line-height: var(--line-height-md);
`;
