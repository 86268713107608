import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Wrapper } from 'styles/layout';
import { Allcaps, Paragraph } from 'styles/typography';
import spacer from 'utils/spacer';

const StyledSectionTextWithAllcaps = styled(Wrapper)`
  ${Allcaps} {
    opacity: 0.7;
    margin-bottom: var(--space-sm);
  }

  ${spacer}
`;

const SectionTextWithAllcaps = (props) => {
  return (
    <StyledSectionTextWithAllcaps size='text' {...props}>
      {props.heading && <Allcaps>{props.heading}</Allcaps>}
      <Paragraph level={1}>{props.desc}</Paragraph>
    </StyledSectionTextWithAllcaps>
  );
};

SectionTextWithAllcaps.propTypes = {
  heading: PropTypes.string,
  desc: PropTypes.string.isRequired,
};

export default SectionTextWithAllcaps;
