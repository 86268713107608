import React from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import { motion } from 'framer-motion';
import { Motion, fadeIn, fadeInUp } from 'components/motion';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { ModalImage } from 'components/image';
import { ReactVimeoVideo } from 'components/video';
import { Container, Wrapper, Grid } from 'styles/layout';
import { Caption } from 'styles/typography';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledDevelopment = styled(Container)`
  .desy {
    margin-bottom: var(--space-md);
  }

  ${Caption} {
    margin-top: var(--space-xs);
  }

  ${Grid} {
    margin-bottom: var(--space-lg);
  }

  ${down(breakpoints.xs)} {
    ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

const Development = ({ content }) => {
  const { heading, subsections } = content;

  return (
    <StyledDevelopment>
      <SectionTitle sectionTitle='Development' heading={heading} />
      <Wrapper size='xl' mb='var(--space-lg)'>
        <SectionTextWithSubtitle
          heading={subsections[0].heading}
          desc={subsections[0].desc}
          mb='var(--space-md)'
        />
        <Motion variants={fadeIn}>
          <Wrapper as='figure' size='lg'>
            <ModalImage
              modalSize='xxl'
              path='volungo'
              name='mvp-scope'
              format='png'
              sizes={[960, 1440, 1920]}
              alt='MVP and Vision'
            />
            <Caption as='figcaption' textAlign='center'>
              MVP scope
            </Caption>
          </Wrapper>
        </Motion>
      </Wrapper>
      <Wrapper size='xl' mb='var(--space-lg)'>
        <SectionTextWithSubtitle
          heading={subsections[1].heading}
          desc={subsections[1].desc}
          mb='var(--space-md)'
        />
        <Motion variants={fadeInUp}>
          <figure className='desy'>
            <ModalImage
              path='volungo'
              name='desy-styleguide'
              format='jpg'
              sizes={[1440, 2160, 2880]}
              alt='Volungo design system styleguide'
            />
            <Caption as='figcaption' textAlign='center'>
              Volungo design system styleguide
            </Caption>
          </figure>
        </Motion>
        <Motion variants={fadeInUp}>
          <figure className='desy'>
            <ModalImage
              path='volungo'
              name='desy-components'
              format='jpg'
              sizes={[1440, 2160, 2880]}
              alt='Volungo design system components'
            />
            <Caption as='figcaption' textAlign='center'>
              Volungo design system components
            </Caption>
          </figure>
        </Motion>
      </Wrapper>
      <Wrapper size='xl'>
        <SectionTextWithSubtitle
          heading={subsections[2].heading}
          desc={subsections[2].desc}
          mb='var(--space-md)'
        />
        <Motion variants={fadeIn}>
          <ReactVimeoVideo
            cover={null}
            caption='VolunGO MVP Video'
            videoId='545184894'
          />
        </Motion>
      </Wrapper>
    </StyledDevelopment>
  );
};

export default Development;
