import styled, { css } from 'styled-components/macro';
import { Button } from '../Button.styled';

const StyledCallToAction = styled(Button)`
  display: inline-flex;
  align-items: center;
  position: relative;

  ${(props) => {
    switch (props.type) {
      case 'primary':
      case 'secondary':
        return blockStyle;
      case 'tertiary':
        return inlineStyle;
      default:
        return;
    }
  }}
`;

const blockStyle = css`
  .btnIcon {
    display: inline-block;
    position: relative;
    transition: all 150ms var(--ease-out);
    height: var(--font-size-button1);
    line-height: 1;

    svg {
      width: 1.2rem;
      height: 1.2rem;
      position: absolute;
      opacity: 0;
      top: 0;
      transform: translateY(-0.2rem);
      left: -0.4rem;
      transition: all 250ms var(--ease-out);
    }
  }

  &:hover .btnIcon {
    padding-right: 1rem;

    svg {
      opacity: 1;
      left: 0.4rem;
    }
  }
`;
const inlineStyle = css`
  ::after {
    position: absolute;
    bottom: 4px;
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background-color: currentColor;
    transition: all 200ms var(--ease-out);
  }

  .btnIcon {
    display: inline-block;
    margin-left: var(--space-xxs);
    transition: all 150ms var(--ease-out);

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &:hover {
    ::after {
      width: 95%;
    }
  }
`;

export default StyledCallToAction;
