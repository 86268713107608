import React from 'react';
import { Helmet } from 'react-helmet-async';
import Demo from './Demo';
import Research from './Research';
import Analysis from './Analysis';
import Concept from './Concept';
import Solution from './Solution';
import Prototyping from './Prototyping';
import Header from 'templates/header/Header';
import Summary from 'templates/summary/Summary';
import Footer from 'templates/footer/Footer';
import InpageNav from 'templates/navigation/inpage-nav/InpageNav';
import Reflection from 'templates/reflection/Reflection';
import { FullBleedText, ProductHighlight } from 'patterns/highlight';
import { Page, Section } from 'components/container';
import data from 'data/yhteispot';

const Yhteispot = () => {
  const name = 'yhteispot';
  const {
    header,
    summary,
    demo,
    highlight,
    research,
    analysis,
    concept,
    solution,
    prototyping,
    reflection,
  } = data;

  return (
    <Page>
      <Helmet>
        <title>Yhteispot | Yuvin Ha</title>
        <meta
          name='description'
          content='a service design project was conducted in collaboration with the city of Espoo to create a grilling and campfire network along the waterfront of the city. As a result, a new collaborative service model for responsible leisure activities is presented.'
        />
      </Helmet>
      <InpageNav />
      <Header content={header} />
      <Section
        id='summary'
        color={`var(--color-${name}-text)`}
        bg={`var(--color-${name}-bg)`}
        pb='var(--space-xxl)'
      >
        <Summary
          name={name}
          content={summary}
          videoInfo={{
            id: 264884156,
            caption:
              'Experience prototype video depicts how the experiences will look like',
          }}
        />
        <Demo name={name} content={demo} />
      </Section>
      <FullBleedText
        bg={`var(--color-${name}-base)`}
        heading={highlight.brief.heading}
        desc={highlight.brief.desc}
      />
      <Section id='research'>
        <Research content={research} />
      </Section>
      <Section id='analysis' pb='var(--space-xxl)'>
        <Analysis content={analysis} />
      </Section>
      <FullBleedText
        bg={`var(--color-${name}-text)`}
        heading={highlight.hmw.heading}
        desc={highlight.hmw.desc}
      />
      <Section id='concept'>
        <Concept content={concept} />
      </Section>
      <Section id='solution'>
        <ProductHighlight
          bg={`var(--color-${name}-bg)`}
          color={`var(--color-${name}-text)`}
          imgSrc={{
            path: 'yhteispot',
            name: 'product-highlight',
            format: 'png',
            sizes: [1200, 1800, 2400],
            alt: 'Product image',
          }}
          heading={highlight.product.heading}
          desc={highlight.product.desc}
        />
        <Solution content={solution} />
      </Section>
      <Section id='prototyping'>
        <Prototyping content={prototyping} />
      </Section>
      <Section id='reflection'>
        <Reflection content={reflection} />
      </Section>
      <Footer />
    </Page>
  );
};

export default Yhteispot;
