import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { IoMenuOutline, IoCloseOutline } from 'react-icons/io5';
import { StyledNavbar } from './Navbar.styled';
import Menu from './Menu';
import theme from 'styles/theme';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation().pathname.slice(1);

  const toggleMenu = (e) => setShowMenu(!showMenu);

  const handleLogoOnScroll = () => {
    const logo = document.querySelector('.navbar-logo');
    const mq = `(max-width: ${theme.breakpoints.lg})`;

    if (
      window.matchMedia(mq).matches &&
      window.scrollY > logo.getBoundingClientRect().height
    ) {
      logo.classList.add('hide');
    } else {
      logo.classList.remove('hide');
    }
  };

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  // HIDE LOGO WHEN SCROLLING
  useEffect(() => {
    window.addEventListener('scroll', handleLogoOnScroll);

    return () => {
      window.removeEventListener('scroll', handleLogoOnScroll);
    };
  }, []);

  return (
    <StyledNavbar showMenu={showMenu}>
      <Link to='/'>
        <img
          className='navbar-logo'
          src={`${process.env.PUBLIC_URL}/assets/icons/logo.svg`}
          alt='logo'
        />
      </Link>
      <div className='menu-icon' onClick={toggleMenu}>
        {showMenu ? <IoCloseOutline /> : <IoMenuOutline />}
      </div>
      <CSSTransition
        in={showMenu}
        timeout={300}
        classNames='slide'
        unmountOnExit
      >
        <Menu setShowMenu={setShowMenu} showMenu={showMenu} />
      </CSSTransition>
    </StyledNavbar>
  );
};

export default Navbar;
