import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { SectionTextWithSubtitle } from 'patterns/section';
import { Motion, fadeIn, fadeInUp, parentVariants } from 'components/motion';
import { ModalImage } from 'components/image';
import { Container, Grid, Wrapper } from 'styles/layout';
import { Card } from 'styles/card';
import { Subtitle, Paragraph, Footnote } from 'styles/typography';
import theme from 'styles/theme';

const COLOR_LOCAL_VOICE = '#ffa801';
const COLOR_SERVICE_POINT = '#753ef7';
const { space, down, breakpoints } = theme;

const StyledSolution = styled(Container)`
  padding-top: ${space[10]};

  ${Card} {
    border: 1px solid;
    background: var(--color-white);
    padding: var(--space-lg) var(--space-md);

    ${Subtitle} {
      margin-bottom: var(--space-sm);
    }

    ${Paragraph} {
      margin-bottom: var(--space-lg);
    }
  }

  .local-voice {
    border-color: ${COLOR_LOCAL_VOICE};
  }

  .service-point {
    border-color: ${COLOR_SERVICE_POINT};
  }

  .journey-map {
    img {
      border: 1px solid var(--color-grey-200);
    }

    ${Footnote} {
      line-height: var(--line-height-xl);
      max-width: var(--wrapper-text);
      margin: auto;
      margin-top: var(--space-md);
    }
  }

  ${down(breakpoints.xs)} {
    ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

const Solution = ({ content }) => {
  const {
    subsections,
    features: { localVoice, servicePoint },
  } = content;

  return (
    <StyledSolution>
      <Wrapper size='lg' mb='var(--space-xl)'>
        <SectionTextWithSubtitle
          heading={subsections[0].heading}
          desc={subsections[0].desc}
          mb='var(--space-lg)'
        />
        <Wrapper size='sm' mb='var(--space-xl)'>
          <Motion variants={fadeIn}>
            <ModalImage
              modalSize='md'
              path='yhteispot'
              name='service-model'
              format='png'
              sizes={[960, 1440, 1920]}
              alt='Service model diagram'
            />
          </Motion>
        </Wrapper>
        <Motion variants={parentVariants}>
          <Grid col={2} gap='lg'>
            <motion.div variants={fadeInUp}>
              <Card className='local-voice'>
                <Subtitle level={2}>{localVoice.heading}</Subtitle>
                <Paragraph>{localVoice.desc}</Paragraph>
                <ModalImage
                  modalSize='lg'
                  path='yhteispot'
                  name='diagram-voice'
                  format='png'
                  sizes={[960, 1440, 1920]}
                  alt='Local Voice system flow'
                />
              </Card>
            </motion.div>
            <motion.div variants={fadeInUp}>
              <Card className='service-point'>
                <Subtitle level={2}>{servicePoint.heading}</Subtitle>
                <Paragraph>{servicePoint.desc}</Paragraph>
                <ModalImage
                  modalSize='lg'
                  path='yhteispot'
                  name='diagram-service'
                  format='png'
                  sizes={[960, 1440, 1920]}
                  alt='Rental Service Point system flow'
                />
              </Card>
            </motion.div>
          </Grid>
        </Motion>
      </Wrapper>
      <Wrapper size='lg'>
        <SectionTextWithSubtitle
          heading={subsections[1].heading}
          desc={subsections[1].desc}
          mb='var(--space-md)'
        />
        <Motion variants={fadeIn}>
          <figure className='journey-map'>
            <ModalImage
              path='yhteispot'
              name='journey-map'
              format='jpg'
              sizes={[1440, 2160, 2880]}
              alt='User journey map'
            />
            <Footnote as='figcaption'>{subsections[1].story}</Footnote>
          </figure>
        </Motion>
      </Wrapper>
    </StyledSolution>
  );
};

export default Solution;
