import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

const LinkStyle = css`
  text-decoration: none;
  display: inline-block;
  position: relative;

  &:link,
  &:visited {
    color: currentColor;
    transition: all 0.2s ease-in-out;
  }

  // &:hover,
  // &:active {
  // }
`;

export const ExternalLink = styled(Link)`
  color: inherit;
  cursor: pointer;
  line-height: inherit;
  text-decoration: underline;
  text-underline-offset: 1px;

  &:hover {
    opacity: 0.7;
    text-decoration: none;
    transition: all 200ms var(--ease-in-out);
  }
`;

export const StyledLink = styled(Link)`
  ${LinkStyle}
`;

export const StyledNavLink = styled(NavLink)`
  ${LinkStyle}
  position: relative;
  opacity: 0.8;

  &::after {
    position: absolute;
    bottom: 2px;
    content: '';
    display: block;
    width: 0;
    height: 1.5px;
    background-color: #fff;
  }

  &:hover {
    opacity: 1;

    &::after {
      width: 100%;
      transition: all 0.3s ease-in-out;
    }
  }

  &.active {
    opacity: 1;
    font-weight: var(--font-weight-medium);
  }
`;
