import React from 'react';
import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import { IoLockClosed } from 'react-icons/io5';
import useToggleState from 'hooks/useToggleState';
import {
  StyledMoreWorksItem,
  StyledMoreWorksInProgress,
} from './MoreWorksItem.styled';
import { Image } from 'components/image';
import { Chip } from 'components/chips';
import { Headline, Paragraph } from 'styles/typography';

const MoreWorkItem = ({ work }) => {
  const { title, name, description, isProtected, isReady, isExternal } = work;
  const [imageClass, toggleImageClass] = useToggleState(false);

  return isReady ? (
    <StyledMoreWorksItem
      as={Link}
      to={
        !isExternal
          ? `/${name}`
          : {
              pathname: `https://yuvinha-legacy.netlify.app/${name}.html`,
            }
      }
      target={isExternal ? '_blank' : ''}
      title={`${name}`}
    >
      {isProtected && <IoLockClosed className='lock-icon' />}
      <div className='image'>
        <Image
          className={imageClass ? 'in-scale' : 'out-scale'}
          onMouseEnter={() => toggleImageClass(true)}
          onMouseLeave={() => toggleImageClass(false)}
          path='home'
          name={name}
          format='jpg'
          sizes={[600, 900, 1200]}
          alt={`${name} mockup`}
        />
      </div>
      <div className='content'>
        <div className='title'>
          <Headline as='h3' level={6}>
            {title}
          </Headline>
          {isExternal && <FiExternalLink />}
        </div>
        <Paragraph level={2}>{description}</Paragraph>
      </div>
    </StyledMoreWorksItem>
  ) : (
    <StyledMoreWorksInProgress
      shadow='xl'
      radius='sm'
      padding='var(--space-xs)'
    >
      <Image
        path='home'
        name={name}
        format='jpg'
        sizes={[600, 900, 1200]}
        alt={`${name} mockup image`}
      />
      <div className='content'>
        <div>
          <Headline as='h3' level={6}>
            {title}
          </Headline>
          <Chip>Under renovation</Chip>
        </div>
        <Paragraph level={2}>{description}</Paragraph>
      </div>
    </StyledMoreWorksInProgress>
  );
};

export default MoreWorkItem;
