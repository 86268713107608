import styled, { css } from 'styled-components/macro';
import { Headline, Allcaps, Paragraph } from 'styles/typography';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const responsive = css`
  ${down(breakpoints.md)} {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-column: auto / auto;

    .image {
      width: 80%;
      padding: 0;
      margin-top: var(--space-xs);

      img {
        object-fit: cover;
        margin: 0 auto;
      }
    }

    .content {
      width: 100%;
      padding-right: var(--space-lg);
      padding-bottom: 0;
    }
  }

  ${down(breakpoints.xs)} {
    .image {
      width: 100%;
    }

    .content {
      padding: var(--space-lg);
      padding-bottom: 0;
    }
  }
`;

const full = css`
  flex-direction: row;
  align-items: center;
  grid-column: 1 / span 2;

  .image {
    width: 50%;
    height: 100%;

    img {
      object-fit: contain;
      height: 100%;
      margin-top: var(--space-sm);
    }
  }

  .content {
    width: 50%;
    padding: var(--space-xl) var(--space-lg);
    padding-right: var(--space-md);
  }

  ${responsive};
`;

const half = css`
  flex-direction: column;
  justify-content: space-between;

  .content {
    width: 100%;
    padding: var(--space-lg);
    padding-top: var(--space-xl);
    padding-bottom: 0;
  }

  ${responsive};
`;

export const StyledFeaturedItem = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  background: var(--color-${(props) => props.name}-bg);
  color: var(--color-${(props) => props.name}-text);
  border-radius: var(--radius-md);
  overflow: hidden;

  .lock-icon {
    font-size: 0.875rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: currentColor;
    opacity: 0.25;
    z-index: var(--z-index-lockIcon);
  }

  ${Allcaps} {
    font-size: 0.875rem;
    margin-bottom: var(--space-xs);
  }
  ${Headline} {
    margin-bottom: var(--space-sm);
  }

  ${Paragraph} {
    margin-bottom: var(--space-sm);
    font-weight: var(--font-weight-light);
    line-height: var(--line-height-md);
    color: inherit;
  }

  ${(props) => (props.full ? full : half)};
`;
