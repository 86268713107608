const inpageNavLinks = {
  volungo: [
    { name: 'Top', id: 'top' },
    { name: 'Summary', id: 'summary' },
    { name: 'Background', id: 'background' },
    { name: 'Research', id: 'research' },
    { name: 'Solution', id: 'solution' },
    { name: 'Prototyping', id: 'prototyping' },
    { name: 'Development', id: 'development' },
    { name: 'Reflection', id: 'reflection' },
  ],
  yhteispot: [
    { name: 'Top', id: 'top' },
    { name: 'Summary', id: 'summary' },
    { name: 'Research', id: 'research' },
    { name: 'Analysis', id: 'analysis' },
    { name: 'Concept', id: 'concept' },
    { name: 'Solution', id: 'solution' },
    { name: 'Prototyping', id: 'prototyping' },
    { name: 'Reflection', id: 'reflection' },
  ],
  'cs-expert': [
    { name: 'Top', id: 'top' },
    { name: 'Summary', id: 'summary' },
    { name: 'Methodology', id: 'methodology' },
    { name: 'Research', id: 'research' },
    { name: 'Analysis', id: 'analysis' },
    { name: 'Concept', id: 'concept' },
    { name: 'Prototyping', id: 'prototyping' },
    { name: 'Reflection', id: 'reflection' },
  ],
};

export default inpageNavLinks;
