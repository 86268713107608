import styled from 'styled-components/macro';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: var(--z-index-menu);
  top: 0;
  right: 0;
  width: 33%;
  height: 100vh;
  background-color: var(--color-black);
  padding: 0 var(--space-xl);

  transition: all 0.3s ease-in-out;
  color: var(--color-white);
  font-size: var(--font-size-body2);
  font-weight: var(--font-weight-light);
  letter-spacing: var(--letter-spacing-xs);

  ${down(breakpoints.sm)} {
    width: 50%;
  }

  ${down(breakpoints.xs)} {
    width: 100%;
  }

  ul {
    list-style: none;

    li {
      &:not(:last-of-type) {
        margin-bottom: 1.25rem;
      }
    }

    :last-of-type {
      margin-top: var(--space-md);

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-grey-500);
        opacity: 0.8;
        margin-bottom: var(--space-md);
      }
    }
  }

  .external-link {
    display: inline-flex;
    align-items: center;
    svg {
      font-size: var(--font-size-body2);
      margin-left: var(--space-xs);
    }
  }
`;
