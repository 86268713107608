import React, { useReducer } from 'react';
import modalReducer from './modalReducer';
import ModalContext from './ModalContext';
import { OPEN_MODAL, CLOSE_MODAL } from '../types';
import Modal from 'components/modal/Modal';

const ModalState = (props) => {
  const initialState = {
    isOpen: false,
    modalContent: null,
  };

  const [state, dispatch] = useReducer(modalReducer, initialState);

  // OPEN MODAL
  const openModal = (content) =>
    dispatch({ type: OPEN_MODAL, payload: content });

  // CLOSE MODAL
  const closeModal = () => dispatch({ type: CLOSE_MODAL });

  return (
    <ModalContext.Provider
      value={{
        modalContent: state.modalContent,
        isOpen: state.isOpen,
        openModal,
        closeModal,
      }}
    >
      <Modal />
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalState;
