import React, { useEffect, useRef } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { works } from 'data/common';
import { StyledNavLink } from 'styles/link';
import { StyledMenu } from './Menu.styled';

const Menu = ({ showMenu, setShowMenu }) => {
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within, then close
      if (showMenu && ref.current && !ref.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
    // eslint-disable-next-line
  }, [showMenu]);

  return (
    <StyledMenu ref={ref}>
      <ul>
        <li>
          <StyledNavLink exact to='/'>
            Home
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink exact to='/about'>
            About
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink
            className='external-link'
            exact
            to={{
              pathname:
                'https://drive.google.com/file/d/1RPIa8n9vlQGLt_QNssGcCDKIr7OPu7nj/view',
            }}
            target='_blank'
          >
            Resume <FiExternalLink />
          </StyledNavLink>
        </li>
      </ul>
      <ul>
        {Object.keys(works).map((type) =>
          works[type].map(
            (work) =>
              work.isReady && (
                <li key={work.id}>
                  <StyledNavLink exact to={`/${work.name}`}>
                    {work.title}
                  </StyledNavLink>
                </li>
              )
          )
        )}
      </ul>
    </StyledMenu>
  );
};

export default Menu;
