export const parentVariants = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.3,
    },
  },
};

export const fadeIn = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      type: 'tween',
      delay: 0.2,
      duration: 0.5,
      ease: 'easeIn',
    },
  },
};

export const fadeInUp = {
  init: {
    opacity: 0,
    y: 100,
  },
  anim: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.5,
      ease: 'easeIn',
    },
  },
};

export const fadeInLeft = {
  init: {
    opacity: 0,
    x: 100,
  },
  anim: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'tween',
      duration: 0.5,
      ease: 'easeIn',
    },
  },
};

export const zoomIn = {
  init: {
    opacity: 0,
    scale: 0,
  },
  anim: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      duration: 1.5,
    },
  },
};

export const pulse = {
  init: {
    scale: 1,
  },
  anim: {
    scale: [1, 1.03, 1],
    transition: {
      ease: 'easeInOut',
      delay: 0.4,
      duration: 0.8,
    },
  },
};
