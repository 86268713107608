import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import theme from 'styles/theme';

export const Button = styled.button`
  --color-primary: ${(props) =>
    props.name
      ? `var(--color-${props.name}-text)`
      : props.color || theme.colors.primary};

  font-family: inherit;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-button1);
  line-height: 1;
  letter-spacing: var(--letter-spacing-lg);
  text-align: center;

  display: ${(props) => (props.$block ? 'block' : 'inline-block')};
  padding: var(--space-sm) var(--space-md);

  border-radius: 9999px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${(props) => {
    switch (props.type) {
      case 'primary':
        return primary;
      case 'secondary':
        return secondary;
      case 'tertiary':
        return tertiary;
      default:
        return;
    }
  }}
`;

Button.propTypes = {
  $block: PropTypes.bool,
  name: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string.isRequired,
};

const primary = css`
  color: var(--color-white);
  background: var(--color-primary);
  border: var(--border-sm) var(--color-primary);
`;
const secondary = css`
  color: var(--color-primary);
  background: transparent;
  border: var(--border-sm) var(--color-primary);
`;
const tertiary = css`
  color: var(--color-primary);
  background: transparent;
  border: none;
  padding: var(--space-xs) var(--space-xxs);
`;
