import React from 'react';
import styled from 'styled-components/macro';
import { Headline, Paragraph } from 'styles/typography';

const StyledSummaryText = styled.div`
  ${Headline} {
    margin-bottom: var(--space-md);
  }

  &:first-of-type {
    margin-bottom: var(--space-xl);
  }
`;

const SummaryText = ({ heading, body }) => {
  return (
    <StyledSummaryText>
      <Headline level={4}>{heading}</Headline>
      <Paragraph level={1}>{body}</Paragraph>
    </StyledSummaryText>
  );
};

export default SummaryText;
