import { TAG_WELL, TAG_NEXT, TAG_CHALLENGE } from 'constant/constant';

const volungo = {
  header: {
    heading: 'VolunGO',
    subheading:
      'Develop a dual platform for intelligent matching of volunteers and nonprofit organizations',
    hero: {
      path: 'volungo',
      name: 'hero',
      format: 'jpg',
      sizes: [1440, 2160, 2880],
      alt: 'volungo hero image',
    },
    info: {
      timeframe: 'Jan ‐ Mar 2021',
      client: 'Self-initiated startup',
      role: 'Sole designer — Research, Analysis, Ideation, Concept development, Story mapping, Wireframes, UX/UI/Visual design, Prototyping, User testing, Frontend development (CSS/SASS)',
      team: 'Aleksei Nepochatykh (SW), Goran Ivkovic Ivandekic (AI), Ingrid Adam (PM), Michelle Torres-Linke (AC), Meruyert Karim (SW)',
    },
  },
  summary: {
    challenge:
      'Nonprofit organizations constantly compete for funding and volunteers. Technology could transform the nonprofit sector and help organizations with better delivery on mission. In discovery phase, we found out that many people would like to volunteer but struggle to find suitable projects while nonprofit organizations very often lack qualified volunteers.',
    solution:
      'We created a new two-sided volunteering platform, VolunGO, to tackle this problem by matching them with each other. This web-based app provides a place where potential volunteers can find the best volunteer opportunities in their cities while nonprofit organizations get help to find the highly qualified volunteers who match with their opportunities. An MVP of the product was developed and tested with users in three months.',
  },
  demo: {
    volunteer: {
      signup: {
        heading: 'Simple 3-step sign up',
        desc: 'Volunteers can already get filtered projects based on their location, age, and their interests after the 3-step sign up process.',
      },
      preference: {
        heading: 'More personalized feeds by setting preferences',
        desc: 'VolunGO tailors the project list based on the selection of the types of work that users want to engage in and the skills they want to use.',
      },
      favorites: {
        heading: 'More accurate recommendations by favoriting projects',
        desc: 'VolunGO keep learning about what a volunteer is interested in and trying to recommend projects that perfectly fit.',
      },
      join: {
        heading: 'Easy to join the next projects',
        desc: 'Volunteers can easily find, learn, and join a suitable project. Also, they can keep track of the progress in one place.',
      },
    },
    organization: {
      signup: {
        heading: 'Simple sign up with the organization email',
        desc: 'Nonprofit organizations will be ready to be matched with qualified volunteers after 3-step sign up and verification.',
      },
      project: {
        heading: 'Create a new project and invite potential volunteers',
        desc: 'Organizations easily create a new project by following the steps and they can already invite potential volunteers in the process.',
      },
    },
  },
  highlight: {
    hmw: '"How might we bring like-minded people together to make humanitarian aid reach more populations by matching them with each other?”',
    product:
      'VolunGO enables intelligent matching of volunteers and nonprofit organizations.',
  },
  background: {
    heading: 'Understand the Problem Space',
    desc: 'At DPS, we aimed to create an innovative digital product that can bring a positive social impact. We focused on the nonprofit sector, which constantly competes for funding and volunteers. We assumed that technology could transform the nonprofit sector and help organizations with better delivery on mission. To understand the problem space better, we surveyed 91 potential volunteers and interviewed 7 former/current staffs from 6 nonprofit organizations.',
    researchData: [
      { group: 'Potential Volunteers', participants: 91 },
      { group: 'Small Organizations', participants: 2 },
      { group: 'Mid-Size/Large Organizations', participants: 4 },
    ],
    partners: [
      { path: 'volungo', name: 'ashia', format: 'png', sizes: [100, 200] },
      { path: 'volungo', name: 'gsbtb', format: 'png', sizes: [100, 200] },
      { path: 'volungo', name: 'labdoo', format: 'png', sizes: [100, 200] },
      { path: 'volungo', name: 'leapfrog', format: 'png', sizes: [100, 200] },
      { path: 'volungo', name: 'salsshoes', format: 'png', sizes: [100, 200] },
      { path: 'volungo', name: 'scope', format: 'png', sizes: [100, 200] },
    ],
  },
  research: {
    heading: 'Define the right problem to solve',
    desc: 'From the research, we found out pain points that volunteers and NGOs are facing along their volunteer journey. To summaize, NGOs want to find right volunteers to pursue their mission and people want to have suitable opportunities to help people in need with low effort, however, there is a gap between them.',
    insights: {
      volunteer: [
        {
          insight:
            'People want to give something back to the society but they don’t know how to do.',
          quotes: [
            {
              quote:
                'I wanted to make a positive impact, wanted to bring change to things I felt were wrong/ignored in the community or society, wanted to use my skills to help others.',
              quotedBy: 'Female, 18-24, British',
            },
          ],
        },
      ],
      organization: [
        {
          insight: 'Want to reach out to the broader public',
          quotes: [
            {
              quote:
                'Only very few contact us. Just as a traveler without any idea where to bring the laptop, too. [...] They are the majority off all our flight sponsors.',
              quotedBy: 'Labdoo',
            },
          ],
        },
        {
          insight: 'Extra time and effort to find the right volunteers',
          quotes: [
            {
              quote:
                "They would get too much for a person to handle. You know, they're just too many applications. The process was too hard. So it just took too much time.",
              quotedBy: 'Give Something Back To Berlin (GSBTB)',
            },
          ],
        },
      ],
    },
  },
  solution: {
    heading: 'Create a solution',
    desc: 'We created VolunGO, a web-based platform that enables intelligent matching of volunteers and nonprofit organizations. It helps nonprofit organizations find the best volunteers who match with their projects while volunteers can find the most suitable volunteer opportunities in their cities.',
  },
  prototyping: {
    heading: 'Build-Measure-Learn to validate assumptions',
    desc: 'To validate if the product creates value for stakeholders and if it is easy and simple enough for users to use, we tested the assumptions with prototypes iteratively. We interviewed 6 volunteers and 3 NGOs and surveyed 12 volunteers and 6 NGOs in each iteration cycle. The results are highlighted as follows.',
    subsections: {
      volunteer: {
        signup: {
          heading: 'Sign up',
          desc: 'Users wanted to know more about what the product can do before sign up and even do it when they want to enrol the project. Swipeable cards can be more engaging and fun, but users felt a little uncomfortable with liking/disliking a volunteer opportunity and the invisibility of its algorithm.',
        },
        preference: {
          heading: 'Setting preferences',
          desc: "Users liked to see personalized recommendations, but they were a little overwhelmed by the process. They didn't want to set their preferences right away after sign up. They felt autonomy and freedom by selecting what they like from the list while they thought the list had too many options.",
        },
      },
      organization: {
        add: {
          heading: 'Sign up & Create new project',
          desc: 'NGOs said both sign-up and creation process are easy and can be done quickly since most of required information is already in hand. They felt a list of requirements for volunteers was somewhat limited and wanted to be able to specify them in more detail.',
        },
        invite: {
          heading: 'Volunteer recommendation and invitation',
          desc: 'NGOs think having suggestions for best fit volunteers would be a great feature by enabling them to save time and effort. They wanted to see more detailed profile of suggested candidates and selectively invite them to the project. ',
        },
      },
      // {
      //   heading: 'Test overview',
      //   desc: 'We needed to validate if the product creates value for stakeholders and if it is easy and simple enough for them to join and use our product since they reported the lack of time and resources in the research. So, we developed wireframe prototypes based on the user story maps and validated assumptions with 6 potential volunteers and 3 NGO workers.',
      // },
      // {
      //   heading: 'Test result',
      //   desc: 'Both products were highly appreciated, but several points for improvement were also identified. Volunteers want to see clear value for sign up and have more transparent information about organizations and the algorithms. NGOs want to make a decision by themselves not by the system when sending an invite to volunteers and making a confirmation. They also want to have all communication with volunteers in one place with the same format.',
      // },
    },
  },
  development: {
    heading: 'Develop a Minimal Viable Product (MVP)',
    subsections: [
      {
        heading: 'MVP scope',
        desc: 'Our MVP enabled to people to register on the platform as volunteers, browse the recommended volunteer opportunities, and send an application to the organizations. It supports nonprofit organizations to create and publish a project on the platform and invite volunteers to the project. But, the recomendation was limited due to the absence of sufficient data to train AI and every communication was held by email not in the platform.',
      },
      {
        heading: 'Design system',
        desc: 'We built the VolunGO design system to have consistent design across the platform, save time and effort for reusable solutions, and deliver results faster based on increased collaboration. Since we used Semantic UI React for frontend development to speed up implementation process, UI components were designed based on Semantic UI built-in elements and modules.',
      },
      {
        heading: 'MVP demo video',
        desc: 'Finally, we recorded a short video to demonstrate our MVP. With an MVP of VolunGo platform, nonprofit organizations can post their projects and proactively invite those who are perfect matches for them. Volunteers can quickly and easily sign up for the platform and join the project that suits their skills and interests. ',
      },
    ],
  },
  reflection: [
    {
      heading: 'Validate the potential of the solution with users right away',
      desc: "By quickly creating a landing page of the initial idea and sharing it on social media sites, we were able to fail early and learn what value this solution could bring to users and what's missing.",
      tags: [TAG_WELL],
    },
    {
      heading: 'Test assumptions quickly and iteratively',
      desc: 'By testing our assumptions with users using a clickable wireframe prototype, we were able to eliminate uncertainty before jumping into development.',
      tags: [TAG_WELL],
    },
    {
      heading: 'Communication is a key to manage insecurities and worries',
      desc: "Not everyone had prior experience with design thinking and was comfortable with working with ambiguity. By communicating mutually and constantly, I was able to trigger everyone's active engagement and bring out their best while leading the design-thinking process.",
      tags: [TAG_CHALLENGE, TAG_NEXT],
    },
    {
      heading: 'Be agile to make design and development cooperation smoother',
      desc: 'By working in a Build-Measure-Learn cycle without waiting until everything is ready, we worked very smoothly and efficiently, thereby building and launching a product faster than other teams.',
      tags: [TAG_WELL],
    },
    {
      heading:
        'Define the scope of the project given available resources and timeframe',
      desc: 'None of us were not experienced volunteers and had connections with nonprofit organizations, which caused us to spend first few weeks to contact and find the NGOs who are wiling to work/talk with us. Therefore, we lost time that we could have used for iteration.',
      tags: [TAG_NEXT],
    },
  ],
};

export default volungo;
