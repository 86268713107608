import React from 'react';
import styled from 'styled-components/macro';
import MoreWorksItem from './MoreWorksItem';
import { Greeting } from 'styles/typography';
import { Grid } from 'styles/layout';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledMoreWorks = styled.div`
  margin-bottom: var(--space-xxl);

  ${Greeting} {
    margin-bottom: var(--space-md);
  }

  ${Grid} {
    row-gap: var(--space-lg);
  }

  ${down(breakpoints.sm)} {
    margin-bottom: var(--space-xl);

    ${Grid} {
      grid-template-columns: repeat(2, 1fr);
      gap: var(--space-md);
    }
  }

  ${down(breakpoints.xs)} {
    ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

const MoreWorks = ({ works }) => {
  return (
    <StyledMoreWorks>
      <Greeting>Archives</Greeting>
      <Grid col={3} gap='lg'>
        {works.map((work) => (
          <MoreWorksItem key={work.id} work={work} />
        ))}
      </Grid>
    </StyledMoreWorks>
  );
};

export default MoreWorks;
