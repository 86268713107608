import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { ModalImage, Image } from 'components/image';
import { Motion, fadeInUp, parentVariants } from 'components/motion';
import { Container, Wrapper, Grid } from 'styles/layout';
import { Caption } from 'styles/typography';

const StyledResearch = styled(Container)`
  ${Caption} {
    margin-top: var(--space-xs);
  }
`;

const Research = ({ content }) => {
  const { heading, subsections } = content;

  return (
    <StyledResearch>
      <SectionTitle sectionTitle='Research' heading={heading} />
      <Wrapper size='xl' mb='var(--space-lg)'>
        <SectionTextWithSubtitle
          heading={subsections[0].heading}
          desc={subsections[0].desc}
          mb='var(--space-md)'
        />
        <Motion variants={parentVariants}>
          <Grid col={2} gap='lg'>
            <motion.figure variants={fadeInUp}>
              <ModalImage
                modalSize='lg'
                path='cs-expert'
                name='workflow'
                format='png'
                sizes={[960, 1440, 1920]}
                alt='Current workflow of CS agents'
              />
              <Caption as='figcaption' textAlign='center'>
                Current workflow of CS agents
              </Caption>
            </motion.figure>
            <motion.figure variants={fadeInUp}>
              <ModalImage
                modalSize='lg'
                path='cs-expert'
                name='stakeholder-map'
                format='png'
                sizes={[960, 1440, 1920]}
                alt='Stakeholder map'
              />
              <Caption as='figcaption' textAlign='center'>
                Stakeholder map
              </Caption>
            </motion.figure>
          </Grid>
        </Motion>
      </Wrapper>
      <Wrapper size='xl'>
        <SectionTextWithSubtitle
          heading={subsections[1].heading}
          desc={subsections[1].desc}
          mb='var(--space-md)'
        />
        <Motion variants={parentVariants}>
          <Grid col={2} gap='lg'>
            <motion.figure className='sketch' variants={fadeInUp}>
              <Image
                path='cs-expert'
                name='research-xgoal'
                format='jpg'
                sizes={[720, 1080, 1440]}
                alt='Xgoal-related activity'
              />
              <Caption as='figcaption' textAlign='center'>
                Xgoal-related activity
              </Caption>
            </motion.figure>
            <motion.figure className='sketch' variants={fadeInUp}>
              <Image
                path='cs-expert'
                name='research-observation'
                format='jpg'
                sizes={[720, 1080, 1440]}
                alt='Observing how CS agents work'
              />
              <Caption as='figcaption' textAlign='center'>
                Observing how CS agents work
              </Caption>
            </motion.figure>
          </Grid>
        </Motion>
      </Wrapper>
    </StyledResearch>
  );
};

export default Research;
