import styled from 'styled-components/macro';
import { Button } from 'components/buttons/Button.styled';
import { Flex } from 'styles/layout/Flex.styled';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

export const StyledFooter = styled(Flex)`
  color: var(--color-white);
  background: var(--color-black);
  font-size: var(--font-size-body1);
  font-weight: var(--font-weight-light);
  padding: var(--space-xl) var(--page-margin);

  ul {
    list-style: none;
  }

  li {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: var(--space-md);
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  svg {
    width: 1.2rem;
    height: 1.2rem;
  }

  ${Button} svg {
    margin-left: 4px;
    vertical-align: text-top;
  }

  ${down(breakpoints.md)} {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }

  ${down(breakpoints.sm)} {
    flex-direction: column;
    gap: 0.75rem;

    li:not(:last-of-type) {
      margin-right: var(--space-lg);
    }
  }

  ${down(breakpoints.xs)} {
    gap: 0.5rem;
  }
`;
