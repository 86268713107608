import React from 'react';
import { Helmet } from 'react-helmet-async';
import Gallery from './Gallery';
import HowItWorks from './HowItWorks';
import Header from 'templates/header/Header';
import Footer from 'templates/footer/Footer';
import { Page, Section } from 'components/container';
import { SectionTextWithAllcaps } from 'patterns/section';
import { Container } from 'styles/layout';
import data from 'data/umbrella';
import theme from 'styles/theme';

const Umbrella = () => {
  const name = 'umbrella';

  const {
    header,
    summary: { description, howItWorks },
  } = data;

  return (
    <Page>
      <Helmet>
        <title>Umbrella Forecaster | Yuvin Ha</title>
        <meta
          name='description'
          content='Umbrella Forecaster is an interactive installation which visualizes a chance of rain in a physical form by opening and folding an umbrella accordingly.'
        />
      </Helmet>
      <Header content={header} />
      <Section color={`var(--color-white)`} bg={`var(--color-grey-900)`}>
        <Container>
          <SectionTextWithAllcaps
            heading={description.heading}
            desc={description.desc}
            mb='var(--space-lg)'
          />
          <Gallery path={name} />
        </Container>
      </Section>
      <Section
        color={`var(--color-white)`}
        bg={`var(--color-grey-900)`}
        style={{ paddingBottom: theme.space[12] }}
      >
        <Container>
          <SectionTextWithAllcaps
            heading={howItWorks.heading}
            desc={howItWorks.desc}
            mb='var(--space-lg)'
          />
          <HowItWorks />
        </Container>
      </Section>
      <Footer />
    </Page>
  );
};

export default Umbrella;
