import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import spacer from 'utils/spacer';
import useInpageNav from 'hooks/useInpageNav';

const StyledSection = styled.section`
  background: ${(props) => props.bg};
  color: ${(props) => props.color};
  padding-top: var(--space-xxl);

  &:last-of-type {
    padding-bottom: var(--space-xxl);
  }

  ${spacer}
`;

const Section = ({ id, color, bg, children, ...props }) => {
  const ref = useInpageNav(id);

  return (
    <StyledSection ref={ref} id={id} color={color} bg={bg} {...props}>
      {children}
    </StyledSection>
  );
};

Section.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  bg: PropTypes.string,
};

export default Section;
