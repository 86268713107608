import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import spacer from 'utils/spacer';

export const Card = styled.div.attrs((props) => ({
  radius: props.radius ? `var(--radius-${props.radius})` : 0,
}))`
  border-radius: ${(props) => props.radius};
  overflow: hidden;

  ${spacer}
`;

export const ClickableCard = styled(Card).attrs((props) => ({
  shadow: props.shadow ? `var(--shadow-${props.shadow})` : 'var(--shadow-md)',
}))`
  &:hover {
    box-shadow: ${(props) => props.shadow};
    transition: box-shadow 300ms var(--ease-out);
  }
`;

Card.propTypes = {
  radius: PropTypes.string,
};

ClickableCard.propTypes = {
  shadow: PropTypes.string,
};
