import styled from 'styled-components/macro';
import theme from '../theme';

const { down, breakpoints } = theme;

export const Container = styled.div`
  max-width: var(--wrapper-xxl);
  margin: auto;

  ${down(breakpoints.lg)} {
    max-width: var(--wrapper-xl);
  }

  ${down(breakpoints.md)} {
    max-width: 100%;
    padding-left: var(--page-margin);
    padding-right: var(--page-margin);
  }
`;

/** LAYOUT (Screen size | Margin | Body | Layout Columns)
 * (https://material.io/design/layout/responsive-layout-grid.html#breakpoints)
 */
// Extra-small (phone portrait)
// 0-576 | 16 |	Scaling |	4

// Small (phone landscrape, tablet portrait)
// 577-920 | 32 |	Scaling |	8

// Medium (tablet lanscape)
// 921-1200 |	48 |	Scaling |	12

// Large (laptop)
// 1201-1400 | Scaling | 1140 | 12

// Extra-Large (desktop)
// 1400+ | Scaling | 1320 |	12
