import React from 'react';
import styled from 'styled-components/macro';
import FeaturedItem from './FeaturedItem.legacy';
import { Greeting } from 'styles/typography';
import { Grid } from 'styles/layout';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledFeatured = styled.div`
  margin-bottom: var(--space-xxl);

  ${Greeting} {
    margin-bottom: var(--space-md);
  }

  ${down(breakpoints.sm)} {
    margin-bottom: var(--space-xl);

    ${Grid} {
      gap: var(--space-md);
    }
  }

  ${down(breakpoints.xs)} {
    ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

const Featured = ({ works }) => {
  return (
    <StyledFeatured>
      <Greeting>Featured Work</Greeting>
      <Grid col={2} gap='md'>
        {works.map(
          (work) => work.isReady && <FeaturedItem key={work.id} work={work} />
        )}
      </Grid>
    </StyledFeatured>
  );
};

export default Featured;
