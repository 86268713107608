import React from 'react';
import styled from 'styled-components/macro';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { Motion, fadeIn } from 'components/motion';
import { ModalImage } from 'components/image';
import { Container, Wrapper } from 'styles/layout';

const StyledPrototyping = styled(Container)`
  ul {
    list-style: none;
    position: relative;
    left: 1.2rem;
    margin-top: var(--space-xs);
  }

  li {
    position: relative;

    &::before {
      content: '\\2022';
      display: inline-block;
      position: absolute;
      left: -1.2rem;
    }
  }
`;

const Prototyping = ({ content }) => {
  const { heading, desc, scenarios } = content;

  return (
    <StyledPrototyping>
      <Wrapper size='text' mb='var(--space-lg)'>
        <SectionTitle sectionTitle='Prototyping' heading={heading} />
        <SectionTextWithSubtitle desc={desc} />
        <ul>
          {scenarios.map((el, idx) => (
            <li key={idx}>{el}</li>
          ))}
        </ul>
      </Wrapper>
      <Wrapper size='xxl'>
        <Motion variants={fadeIn}>
          <ModalImage
            path='yhteispot'
            name='ui-mobile'
            format='jpg'
            sizes={[1440, 2160, 2880]}
            alt='UI Design for mobile web'
            mb='var(--space-lg)'
          />
        </Motion>
        <Motion variants={fadeIn}>
          <ModalImage
            path='yhteispot'
            name='ui-desktop'
            format='jpg'
            sizes={[1440, 2160, 2880]}
            alt='UI Design for desktop web'
          />
        </Motion>
      </Wrapper>
    </StyledPrototyping>
  );
};

export default Prototyping;
