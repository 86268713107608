import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { Motion, parentVariants, fadeInUp } from 'components/motion';
import { Image } from 'components/image';
import { Container, Wrapper, Grid } from 'styles/layout';
import { Caption } from 'styles/typography';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledPrototyping = styled(Container)`
  ${Caption} {
    margin-top: var(--space-xs);
  }

  ${down(breakpoints.xs)} {
    ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

const Prototyping = ({ content }) => {
  const { heading, desc, test } = content;

  return (
    <StyledPrototyping>
      <Wrapper size='text' mb='var(--space-lg)'>
        <SectionTitle sectionTitle='Prototype &amp; Test' heading={heading} />
        <SectionTextWithSubtitle desc={desc} />
      </Wrapper>
      <Wrapper size='xl'>
        <Motion variants={parentVariants}>
          <Grid col={3} gap='md'>
            <motion.figure className='image-evaluation' variants={fadeInUp}>
              <Image
                path='cs-expert'
                name='evaluation-1'
                format='jpg'
                sizes={[720, 1080, 1440]}
                alt='Introduce the overall concept and service flow'
              />
              <Caption as='figcaption'>
                <strong>{test[0].heading}. </strong>
                {test[0].desc}
              </Caption>
            </motion.figure>
            <motion.figure className='image-evaluation' variants={fadeInUp}>
              <Image
                path='cs-expert'
                name='evaluation-2'
                format='jpg'
                sizes={[720, 1080, 1440]}
                alt='Ask to perform the assigned tasks using a prototype'
              />
              <Caption as='figcaption'>
                <strong>{test[1].heading}. </strong>
                {test[1].desc}
              </Caption>
            </motion.figure>
            <motion.figure className='image-evaluation' variants={fadeInUp}>
              <Image
                path='cs-expert'
                name='evaluation-3'
                format='jpg'
                sizes={[720, 1080, 1440]}
                alt='Ask to evaluate the concept and discuss details'
              />
              <Caption as='figcaption'>
                <strong>{test[2].heading}. </strong>
                {test[2].desc}
              </Caption>
            </motion.figure>
          </Grid>
        </Motion>
      </Wrapper>
    </StyledPrototyping>
  );
};

export default Prototyping;
