import React from 'react';
import { StyledDemoText } from './DemoText.styled';
import { Allcaps, Headline, Paragraph } from 'styles/typography';

const DemoText = ({ category, heading, desc }) => {
  return (
    <StyledDemoText>
      {category ? <Allcaps>{category}</Allcaps> : null}
      <Headline level={5}>{heading}</Headline>
      <Paragraph>{desc}</Paragraph>
    </StyledDemoText>
  );
};

export default DemoText;
