import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import {
  Motion,
  fadeInUp as childVariants,
  parentVariants,
} from 'components/motion';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import Insight from 'patterns/Insight';
import { Container, Grid, Wrapper } from 'styles/layout';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledResearch = styled(Container)`
  ${down(breakpoints.sm)} {
    ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

const Research = ({ content }) => {
  const { heading, desc, insights } = content;
  const { volunteer, organization } = insights;

  return (
    <StyledResearch>
      <Wrapper size='text' mb='var(--space-lg)'>
        <SectionTitle sectionTitle='research' heading={heading} />
        <SectionTextWithSubtitle desc={desc} />
      </Wrapper>
      <Wrapper size='xl'>
        <Motion variants={parentVariants}>
          <Grid col={2} gap='lg'>
            <motion.div variants={childVariants}>
              <Insight keyword='volunteer' content={volunteer} />
            </motion.div>
            <motion.div variants={childVariants}>
              <Insight keyword='organization' content={organization} />
            </motion.div>
          </Grid>
        </Motion>
      </Wrapper>
    </StyledResearch>
  );
};

export default Research;
