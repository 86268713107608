import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import {
  Motion,
  fadeInUp as childVariants,
  parentVariants,
} from 'components/motion';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import DataDisplay from 'patterns/DataDisplay';
import Partners from 'patterns/Partners';
import { Container, Grid, Wrapper } from 'styles/layout';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledBackground = styled(Container)`
  ${down(breakpoints.md)} {
    .data-container {
      ${Grid} {
        gap: var(--space-md);
      }
    }
  }
  ${down(breakpoints.xs)} {
    .data-container {
      ${Grid} {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const Background = ({ content }) => {
  const { heading, desc, researchData, partners } = content;

  return (
    <StyledBackground>
      <Wrapper size='text' mb='var(--space-lg)'>
        <SectionTitle sectionTitle='background' heading={heading} />
        <SectionTextWithSubtitle desc={desc} />
      </Wrapper>
      <Motion variants={parentVariants}>
        <motion.div variants={childVariants}>
          <Wrapper className='data-container' size='lg'>
            <Grid col={3} gap='lg'>
              {researchData.map(({ group, participants }, idx) => (
                <DataDisplay key={idx} title={group} data={participants} />
              ))}
            </Grid>
          </Wrapper>
        </motion.div>
        <motion.div variants={childVariants}>
          <Wrapper size='lg' mt='var(--space-md)'>
            <Partners
              imgArray={partners}
              altText='organization logo'
              gap='md'
            />
          </Wrapper>
        </motion.div>
      </Motion>
    </StyledBackground>
  );
};

export default Background;
