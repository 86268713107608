import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Motion, fadeIn, pulse } from 'components/motion';
import { Image } from 'components/image';
import { Container, Wrapper } from 'styles/layout';
import { Allcaps } from 'styles/typography';
import theme from 'styles/theme';

const StyledProductHighlight = styled.div`
  width: 100%;
  padding: var(--space-xl) 0;
  padding-top: ${theme.space[10]};
  background: ${(props) => (props.bg ? props.bg : '#000')};
  color: ${(props) => (props.color ? props.color : '#fff')};

  text-align: center;

  ${Allcaps} {
    margin-bottom: var(--space-sm);
  }

  .desc {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-md);
  }
`;

const ProductHighlight = ({ heading, desc, ...props }) => {
  const { path, name, format, sizes, alt } = props.imgSrc;

  return (
    <StyledProductHighlight {...props}>
      <Container>
        <Motion variants={fadeIn}>
          <Wrapper size='text' mb='var(--space-xl)'>
            {heading ? <Allcaps>{heading}</Allcaps> : null}
            <p className='desc'>{desc}</p>
          </Wrapper>
        </Motion>
        <Wrapper size='xl'>
          <Motion variants={pulse}>
            <Image
              path={path}
              name={name}
              format={format}
              sizes={sizes}
              alt={alt}
            />
          </Motion>
        </Wrapper>
      </Container>
    </StyledProductHighlight>
  );
};

ProductHighlight.propTypes = {
  bg: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default ProductHighlight;
