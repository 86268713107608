import React, { useRef } from 'react';
import { StyledImage } from './Image.styled';

const Image = ({
  path,
  name,
  format,
  sizes,
  alt,
  type = 'image/webp',
  ...props
}) => {
  const imgRef = useRef(null);

  const srcsetWebp = sizes
    .map(
      (size) =>
        `${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${size}.webp ${size}w`
    )
    .join(',');

  const srcsetRaster = sizes
    .map(
      (size) =>
        `${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${size}.${format} ${size}w`
    )
    .join(',');

  return (
    <StyledImage {...props} ref={imgRef}>
      <source type={type} srcSet={srcsetWebp} />
      <source srcSet={srcsetRaster} />
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${sizes[0]}.${format}`}
        alt={alt}
      />
    </StyledImage>
  );
};

export default Image;
