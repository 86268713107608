import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import styled from 'styled-components/macro';
import { Caption } from 'styles/typography';

const StyledReactVimeoVideo = styled.div`
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    border: 1px solid var(--color-grey-200);
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${Caption} {
    margin-top: var(--space-xs);
  }
`;

const ReactVimeoVideo = ({ cover, videoId, caption }) => {
  return (
    <StyledReactVimeoVideo>
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          light={
            cover
              ? `${process.env.PUBLIC_URL}/assets/images/${cover.path}/${cover.name}-${cover.size}.jpg`
              : false
          }
          url={`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`}
          width='100%'
          height='100%'
          playing={cover !== null && true}
          playsinline
          controls
        />
      </div>
      {caption && <Caption textAlign='center'>{caption}</Caption>}
    </StyledReactVimeoVideo>
  );
};

export default ReactVimeoVideo;
