import React from 'react';
import styled from 'styled-components/macro';
import { Wrapper } from 'styles/layout';
import { ModalImage } from 'components/image';
import { Motion, fadeIn } from 'components/motion';
import { Caption } from 'styles/typography';

const StyledHowItWorks = styled(Wrapper)`
  ${Caption} {
    margin-top: var(--space-sm);
  }
`;

const HowItWorks = () => {
  return (
    <StyledHowItWorks size='text'>
      <Motion variants={fadeIn}>
        <figure>
          <ModalImage
            modalSize='xl'
            path='umbrella'
            name='system-flow'
            format='png'
            sizes={[960, 1440, 1920]}
            alt='System flow behind the scene'
          />
          <Caption as='figcaption' textAlign='center'>
            System flow behind the scene
          </Caption>
        </figure>
      </Motion>
    </StyledHowItWorks>
  );
};

export default HowItWorks;
