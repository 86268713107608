const shadowZoo = {
  header: {
    heading: 'Shadow Zoo',
    subheading: 'Interactive art where you play with your hand shadows',
    hero: {
      path: 'shadow-zoo',
      name: 'cover',
      videoId: 398769989,
      sizes: [1440, 2160, 2880],
    },
    info: {
      timeframe: 'February 2019 (3w)',
      tools: 'Arduino, Processing, Autodesk Fusion 360',
      role: 'Project concept, Physical computing, Software development, Fabrication',
      team: 'Lorenzo Marchesi, Yachan Yuan',
    },
  },
  summary: {
    description: {
      heading: 'Project description',
      desc: 'Shadow Zoo is an playful interactive installation where people can play together with their hand shadows. You can use your bare hands to create your own shadow puppets. The unique movements of virtual creatures and the interactions among them create a unique narrative.',
    },
    howItWorks: {
      heading: 'How it works',
      desc: 'We used a normal webcam and the Open CV computer vision library to capture and extract hand shadows. We placed a pedal in front of the tool box to enable audiences to start and stop recording by pressing and releasing it with their foot.',
    },
  },
};

export default shadowZoo;
