import styled from 'styled-components/macro';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledInpageNav = styled.nav`
  position: fixed;
  z-index: var(--z-index-inpageNav);
  top: 50%;
  transform: translateY(-50%);

  ${down(breakpoints.md)} {
    display: none;
  }

  ul {
    position: relative;
    list-style: none;
    padding: var(--space-xs) var(--space-xxs);
    background: var(--color-black);
    border-top-right-radius: var(--radius-sm);
    border-bottom-right-radius: var(--radius-sm);
    transition: all 250ms ease;

    &.expanded {
      padding-right: 120px;
    }
  }
`;

export default StyledInpageNav;
