import React from 'react';
import styled from 'styled-components/macro';
import { Motion, fadeIn, fadeInUp } from 'components/motion';
import { ModalImage } from 'components/image';
import DemoText from 'patterns/demo/DemoText';
import { Container, Wrapper, Grid } from 'styles/layout';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledDemo = styled(Container)`
  margin-top: var(--space-xl);

  .demo-signup img {
    border: 1px solid var(--color-grey-200);
  }

  ${Grid} {
    align-items: center;

    .image-container {
      border-radius: var(--radius-md);
      box-shadow: var(--shadow-xl);
      overflow: hidden;
    }
  }

  ${down(breakpoints.xs)} {
    ${Grid} {
      grid-template-columns: 1fr;
      gap: var(--space-lg);

      .image-container {
        order: 2;
        width: 75vw;
        margin: auto;
      }
    }
  }
`;

const Demo = ({ name, content }) => {
  const { signup, profile, development } = content;

  return (
    <StyledDemo>
      <Wrapper size='text' mb='var(--space-lg)'>
        <DemoText heading={signup.heading} desc={signup.desc} />
      </Wrapper>
      <Motion variants={fadeIn}>
        <ModalImage
          className='demo-signup'
          path={name}
          name='demo-signup'
          format='jpg'
          sizes={[1920, 2880, 3840]}
          alt='Signup process'
        />
      </Motion>
      <Wrapper size='xl' mt='var(--space-xl)'>
        <Grid col={2} gap='xl'>
          <DemoText heading={profile.heading} desc={profile.desc} />
          <Motion className='image-container' variants={fadeInUp}>
            <ModalImage
              modalSize='xl'
              path={name}
              name='demo-profile'
              format='png'
              sizes={[960, 1440, 1920]}
              alt='Profile page'
            />
          </Motion>
        </Grid>
      </Wrapper>
      <Wrapper size='xl' mt='var(--space-xl)'>
        <Grid col={2} gap='xl'>
          <Motion className='image-container' variants={fadeInUp}>
            <ModalImage
              modalSize='xl'
              path={name}
              name='demo-development'
              format='png'
              sizes={[960, 1440, 1920]}
              alt='Development page'
            />
          </Motion>
          <DemoText heading={development.heading} desc={development.desc} />
        </Grid>
      </Wrapper>
    </StyledDemo>
  );
};

export default Demo;
