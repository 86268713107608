import styled from 'styled-components/macro';
import spacer from 'utils/spacer';
import { Grid } from 'styles/layout';

export const StyledImage = styled.picture`
  width: 100%;

  img {
    display: block;
    width: 100%;
    object-fit: cover;
    ${spacer}
  }

  ${Grid} > & {
    img {
      height: 100%;
    }
  }
`;

export const StyledModalImage = styled(StyledImage)`
  cursor: pointer;

  img:hover {
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
`;
