import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Grid, Flex, Wrapper } from 'styles/layout';
import { ModalImage } from 'components/image';
import { Motion } from 'components/motion';

const StyledGallery = styled(Wrapper)`
  img {
    height: 100%;
  }
`;

const parentVariants = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.4,
    },
  },
};

const childVariants = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.5,
      ease: 'easeIn',
    },
  },
};

const Gallery = (props) => {
  const { path } = props;

  return (
    <StyledGallery size='xxl'>
      <Motion variants={parentVariants}>
        <Grid col={2} gap='xs'>
          <motion.div variants={childVariants}>
            <ModalImage
              modalSize='lg'
              path={path}
              name='tool-overall'
              format='jpg'
              sizes={[960, 1440, 1920]}
              alt='overall tool image'
            />
          </motion.div>
          <Flex $column gap='xs'>
            <motion.div variants={childVariants}>
              <ModalImage
                modalSize='lg'
                path={path}
                name='program'
                format='jpg'
                sizes={[960, 1440, 1920]}
                alt={`program image`}
              />
            </motion.div>
            <motion.div variants={childVariants}>
              <ModalImage
                modalSize='lg'
                path={path}
                name='tool-amplifier'
                format='jpg'
                sizes={[960, 1440, 1920]}
                alt='inside the amplifier'
              />
            </motion.div>
          </Flex>
        </Grid>
      </Motion>
    </StyledGallery>
  );
};

export default Gallery;
