import React, { useContext } from 'react';
import ModalContext from 'context/modal/ModalContext';
import { StyledImage, StyledModalImage } from './Image.styled';
import { Wrapper, Divider } from 'styles/layout';
import { Caption } from 'styles/typography';

const ModalImage = ({
  modalSize,
  path,
  name,
  format,
  sizes,
  alt,
  type = 'image/webp',
  ...props
}) => {
  const { openModal } = useContext(ModalContext);

  const srcsetWebp = sizes
    .map(
      (size) =>
        `${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${size}.webp ${size}w`
    )
    .join(',');

  const srcsetRaster = sizes
    .map(
      (size) =>
        `${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${size}.${format} ${size}w`
    )
    .join(',');

  const handleClick = () => {
    const size = sizes[sizes.length - 1];
    const markup = (
      <Wrapper size={modalSize} as='figure'>
        <StyledImage>
          <source
            type='image/webp'
            srcSet={`${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${size}.webp ${size}w`}
          />
          <source
            srcSet={`${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${size}.${format} ${size}w`}
          />
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${size}.${format}`}
            alt={alt}
          />
        </StyledImage>
        <Divider mt='var(--space-lg)' mb='var(--space-xs)' />
        <Caption as='figcaption'>{alt}</Caption>
      </Wrapper>
    );

    openModal(markup);
  };

  return (
    <StyledModalImage {...props} onClick={handleClick}>
      <source type={type} srcSet={srcsetWebp} />
      <source srcSet={srcsetRaster} />
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${sizes[0]}.${format}`}
        alt={alt}
      />
    </StyledModalImage>
  );
};

export default ModalImage;
