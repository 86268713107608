import React from 'react';
import { motion } from 'framer-motion';
import ReflectionItem from './ReflectionItem';
import { Motion } from 'components/motion';
import { SectionTitle } from 'patterns/section';
import { Container, Wrapper } from 'styles/layout';

const parentVariants = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.4,
    },
  },
};

const childVariants = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.5,
      ease: 'easeIn',
    },
  },
};

const Reflection = ({ content: reflections }) => {
  return (
    <Container>
      <Wrapper size='text'>
        <SectionTitle
          mb='var(--space-lg)'
          sectionTitle='Reflection'
          heading='Learnings, challenges, and next steps'
        />
        <Motion variants={parentVariants}>
          {reflections.map(({ heading, desc, tags }, idx) => (
            <motion.div key={idx} variants={childVariants}>
              <ReflectionItem heading={heading} desc={desc} tags={tags} />
            </motion.div>
          ))}
        </Motion>
      </Wrapper>
    </Container>
  );
};

export default Reflection;
