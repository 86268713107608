import React from 'react';
import { Helmet } from 'react-helmet-async';
import Demo from './Demo';
import Methodology from './Methodology';
import Research from './Research';
import Analysis from './Analysis';
import Concept from './Concept';
import Prototyping from './Prototyping';
import Header from 'templates/header/Header';
import Summary from 'templates/summary/Summary';
import Footer from 'templates/footer/Footer';
import InpageNav from 'templates/navigation/inpage-nav/InpageNav';
import Reflection from 'templates/reflection/Reflection';
import { FullBleedText, ProductHighlight } from 'patterns/highlight';
import { Page, Section } from 'components/container';
import data from 'data/cs-expert';

const CsExpert = () => {
  const name = 'cs-expert';
  const {
    header,
    summary,
    demo,
    highlight,
    methodology,
    research,
    analysis,
    concept,
    prototyping,
    reflection,
  } = data;

  return (
    <Page>
      <Helmet>
        <title>CS eXpert | Yuvin Ha</title>
        <meta
          name='description'
          content='The project was conducted for Kone to provide meaningful experiences for customer service agents as well as diminishing their pain points. The process of Expeience goal-driven design and design solutions are presented.'
        />
      </Helmet>
      <InpageNav />
      <Header content={header} />
      <Section
        id='summary'
        color={`var(--color-${name}-text)`}
        bg={`var(--color-${name}-bg)`}
        pb='var(--space-xxl)'
      >
        <Summary
          name={name}
          content={summary}
          videoInfo={{
            id: 399114871,
            caption: 'Demonstration of the solution',
          }}
        />
        <Demo name={name} content={demo} />
      </Section>
      <FullBleedText
        bg={`var(--color-${name}-base)`}
        heading={highlight.hmw.heading}
        desc={highlight.hmw.desc}
      />
      <Section id='methodology'>
        <Methodology content={methodology} />
      </Section>
      <Section id='research'>
        <Research content={research} />
      </Section>
      <Section id='analysis'>
        <Analysis content={analysis} />
      </Section>
      <Section id='concept'>
        <Concept content={concept} />
      </Section>
      <Section id='prototyping' pb='var(--space-xxl)'>
        <Prototyping content={prototyping} />
      </Section>
      <ProductHighlight
        bg={`var(--color-${name}-bg)`}
        color={`var(--color-${name}-text)`}
        imgSrc={{
          path: 'cs-expert',
          name: 'product-highlight',
          format: 'png',
          sizes: [1200, 1800, 2400],
          alt: 'Product image',
        }}
        desc={highlight.product}
      />
      <Section id='reflection'>
        <Reflection content={reflection} />
      </Section>
      <Footer />
    </Page>
  );
};

export default CsExpert;
