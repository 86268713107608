import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Motion, pulse } from 'components/motion';
import { Container, Wrapper } from 'styles/layout';
import { Allcaps } from 'styles/typography';

const StyledFullBleedText = styled.div`
  width: 100%;
  padding: var(--space-xl) 0;
  padding-bottom: calc(var(--space-xl) + 0.5rem);
  background: ${(props) => (props.bg ? props.bg : '#000')};
  color: ${(props) => (props.color ? props.color : '#fff')};
  text-align: center;

  ${Allcaps} {
    margin-bottom: var(--space-sm);
    opacity: 0.7;
  }

  .desc {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-light);
  }
`;

const FullBleedText = ({ heading, desc, ...props }) => {
  return (
    <StyledFullBleedText {...props}>
      <Container>
        <Wrapper size='lg'>
          {heading ? <Allcaps>{heading}</Allcaps> : null}
          <Motion variants={pulse}>
            <p className='desc'>{desc}</p>
          </Motion>
        </Wrapper>
      </Container>
    </StyledFullBleedText>
  );
};

FullBleedText.propTypes = {
  bg: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default FullBleedText;
