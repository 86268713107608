import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

export const Grid = styled.div.attrs((props) => ({
  col: Array.isArray(props.col)
    ? props.col.join(' ')
    : `repeat(${props.col}, 1fr)`,
  gap: props.gap ? `var(--space-${props.gap})` : 0,
}))`
  display: grid;
  grid-template-columns: ${(props) => props.col};
  gap: ${(props) => props.gap};
`;

Grid.propTypes = {
  col: PropTypes.any,
  gap: PropTypes.string,
};
