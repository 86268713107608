import React from 'react';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { Motion, fadeIn } from 'components/motion';
import { ModalImage } from 'components/image';
import { Container, Wrapper } from 'styles/layout';
import { Footnote } from 'styles/typography';

const Methodology = ({ content }) => {
  const { heading, desc, reference } = content;

  return (
    <Container>
      <Wrapper size='text'>
        <SectionTitle sectionTitle='Methodology' heading={heading} />
        <SectionTextWithSubtitle desc={desc} />
        <Motion variants={fadeIn}>
          <ModalImage
            modalSize='xl'
            path='cs-expert'
            name='methodology'
            format='png'
            sizes={[1200, 1800, 2400]}
            alt='Experience-goal driven design approach'
            mt='var(--space-lg)'
            mb='var(--space-sm)'
          />
          <Footnote>{reference}</Footnote>
        </Motion>
      </Wrapper>
    </Container>
  );
};

export default Methodology;
