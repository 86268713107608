import styled from 'styled-components/macro';
import { Flex } from 'styles/layout';
import theme from 'styles/theme';
import { SIZE_MOBILE, SIZE_DESKTOP } from 'constant/constant';

const { down, breakpoints } = theme;

export const StyledDemoVideo = styled.div`
  &:nth-of-type(2n) {
    ${Flex} {
      flex-direction: row-reverse;
    }
  }

  margin-top: ${(props) => (props.type === 'desktop' ? 'var(--space-xxl)' : 0)};

  .video-mobile {
    width: ${SIZE_MOBILE / 2}px;
    height: ${SIZE_MOBILE}px;
  }

  .video-desktop {
    width: ${SIZE_DESKTOP / 2}px;
    height: ${(SIZE_DESKTOP / 2) * 0.625}px;
    border-radius: var(--radius-md);
    borde: 2px solid var(--color-grey-500);
    box-shadow: var(--shadow-lg);
    overflow: hidden;
  }

  ${down(breakpoints.lg)} {
    .video-desktop {
      width: 640px;
      height: 400px;
    }

    .container-desktop {
      max-width: var(--wrapper-xl);
      margin-left: auto;
      margin-right: auto;
    }
  }

  ${down(breakpoints.md)} {
    ${Flex} {
      gap: var(--space-lg);
    }

    .video-desktop {
      width: 512px;
      height: 320px;
    }
  }

  ${down(breakpoints.sm)} {
    &:not(:first-child) {
      margin-top: var(--space-xl);
    }

    .video-mobile {
      width: 270px;
      height: 540px;
    }

    .video-desktop {
      width: 80vw;
      height: 50vw;
    }

    .container-desktop {
      margin-left: auto;
      margin-right: auto;
      max-width: var(--wrapper-text);
      flex-direction: column-reverse !important;
    }
  }

  ${down(breakpoints.xs)} {
    ${Flex} {
      flex-direction: column-reverse !important;
    }

    .video-mobile {
      width: 75vw;
      height: 150vw;
    }

    .video-desktop {
      width: 90vw;
      height: 56.25vw;
    }
  }
`;
