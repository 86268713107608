import { createGlobalStyle } from 'styled-components/macro';
import { darken, rgba } from 'polished';
import theme from './theme';

const {
  space,
  fontSizes,
  fonts,
  fontWeights,
  lineHeights,
  letterSpacings,
  colors,
  borders,
  radii,
  down,
  breakpoints,
  transitions,
  shadows,
  zIndices,
} = theme;

const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;

    --page-margin: 3rem;
    --wrapper-text: 50rem;
    --wrapper-sm: 33.75rem;
    --wrapper-md: 45rem;
    --wrapper-lg: 60rem;
    --wrapper-xl: 71.25rem;
    --wrapper-xxl: 82.5rem;

    --space-xxs: ${space[1]};
    --space-xs: ${space[2]};
    --space-sm: ${space[4]};
    --space-md: ${space[5]};
    --space-lg: ${space[7]};
    --space-xl: ${space[9]};
    --space-xxl: ${space[11]};

    --letter-spacing-xs: ${letterSpacings[1]};
    --letter-spacing-sm: ${letterSpacings[2]};
    --letter-spacing-md: ${letterSpacings[4]};
    --letter-spacing-lg: ${letterSpacings[5]};
    --letter-spacing-xl: ${letterSpacings[7]};

    --line-height-xxs: ${lineHeights[0]};
    --line-height-xs: ${lineHeights[1]};
    --line-height-sm: ${lineHeights[2]};
    --line-height-md: ${lineHeights[3]};
    --line-height-lg: ${lineHeights[4]};
    --line-height-xl: ${lineHeights[5]};
    --line-height-xxl: ${lineHeights[6]};

    --font-family-title: ${fonts.fontTitle};
    --font-family-body: ${fonts.fontBody};

    --font-size-h1: ${fontSizes[12]};
    --font-size-h2: ${fontSizes[11]};
    --font-size-h3: ${fontSizes[9]};
    --font-size-h4: ${fontSizes[7]};
    --font-size-h5: ${fontSizes[6]};
    --font-size-h6: ${fontSizes[5]};
    --font-size-subtitle1: ${fontSizes[5]};
    --font-size-subtitle2: ${fontSizes[4]};
    --font-size-subtitle3: ${fontSizes[3]};
    --font-size-body1: ${fontSizes[5]};
    --font-size-body2: ${fontSizes[4]};
    --font-size-body3: ${fontSizes[3]};
    --font-size-button1: ${fontSizes[3]};
    --font-size-button2: ${fontSizes[3]};
    --font-size-allcaps: ${fontSizes[3]};
    --font-size-caption: ${fontSizes[3]};

    --font-weight-light: ${fontWeights[0]};
    --font-weight-regular: ${fontWeights[1]};
    --font-weight-medium: ${fontWeights[2]};
    --font-weight-bold: ${fontWeights[3]};

    --color-black: ${colors.black};
    --color-white: ${colors.white};
    --color-primary: ${colors.primary};
    --color-secondary: ${colors.secondary};
    --color-error: ${colors.error};
    --color-warning: ${colors.warning};
    --color-success: ${colors.success};
    --color-info: ${colors.info};

    --color-grey-50: ${colors.grey[50]};
    --color-grey-100: ${colors.grey[100]};
    --color-grey-200: ${colors.grey[200]};
    --color-grey-300: ${colors.grey[300]};
    --color-grey-400: ${colors.grey[400]};
    --color-grey-500: ${colors.grey[500]};
    --color-grey-600: ${colors.grey[600]};
    --color-grey-700: ${colors.grey[700]};
    --color-grey-800: ${colors.grey[800]};
    --color-grey-900: ${colors.grey[900]};

    --color-bg-paper: ${colors.background.paper};
    --color-bg-base: ${colors.background.base};

    --color-link-text: ${colors.text.link};
    --color-absence-config-base: ${colors.projects['absence-config'].base};
    --color-absence-config-text: ${darken(
      0.25,
      colors.projects['absence-config'].base
    )};
    --color-absence-config-bg: ${rgba(
      colors.projects['absence-config'].base,
      0.1
    )};
    --color-absence-rounding-base: ${colors.projects['absence-rounding'].base};
    --color-absence-rounding-text: ${darken(
      0.25,
      colors.projects['absence-rounding'].base
    )};
    --color-absence-rounding-bg: ${rgba(
      colors.projects['absence-rounding'].base,
      0.1
    )};
    --color-volungo-base: ${colors.projects.volungo.base};
    --color-volungo-text: ${darken(0.36, colors.projects.volungo.base)};
    --color-volungo-bg: ${rgba(colors.projects.volungo.base, 0.06)};
    --color-yhteispot-base: ${colors.projects.yhteispot.base};
    --color-yhteispot-text: ${darken(0.36, colors.projects.yhteispot.base)};
    --color-yhteispot-bg: ${rgba(colors.projects.yhteispot.base, 0.09)};
    --color-cs-expert-base: ${colors.projects['cs-expert'].base};
    --color-cs-expert-text: ${darken(0.15, colors.projects['cs-expert'].base)};
    --color-cs-expert-bg: ${rgba(colors.projects['cs-expert'].base, 0.08)};
    --color-datavis-base: ${colors.projects['datavis'].base};
    --color-datavis-text: ${darken(0.1, colors.projects['datavis'].base)};
    --color-datavis-bg: ${rgba(colors.projects['datavis'].base, 0.05)};
    --color-customer-portal-base: ${colors.projects['customer-portal'].base};
    --color-customer-portal-text: ${darken(
      0.25,
      colors.projects['customer-portal'].base
    )};
    --color-customer-portal-bg: ${rgba(
      colors.projects['customer-portal'].base,
      0.07
    )};


    --radius-xs: ${radii[1]};
    --radius-sm: ${radii[2]};
    --radius-md: ${radii[4]};
    --radius-lg: ${radii[5]};
    --radius-xl: ${radii[6]};

    --border-xs: ${borders[1]};
    --border-sm: ${borders[2]};
    --border-md: ${borders[3]};
    --border-lg: ${borders[4]};
    --border-xl: ${borders[5]};

    --shadow-xs: ${shadows[1]};
    --shadow-sm: ${shadows[2]};
    --shadow-md: ${shadows[3]};
    --shadow-lg: ${shadows[4]};
    --shadow-xl: ${shadows[5]};
    --shadow-xxl: ${shadows[6]};
    --shadow-xxxl: ${shadows[7]};

    --ease-in-out: ${transitions.easing.easeInOut};
    --ease-out: ${transitions.easing.easeOut};
    --ease-in: ${transitions.easing.easeIn};

    --z-index-navbar: ${zIndices.navbar};
    --z-index-lockIcon: ${zIndices.lockIcon};
    --z-index-inpageNav: ${zIndices.inpageNav};
    --z-index-menu: ${zIndices.menu};
    --z-index-menuIcon: ${zIndices.menuIcon};
    --z-index-tooltip: ${zIndices.tooltip};
    --z-index-modal: ${zIndices.modal};
  }

  body {
    color: var(--color-grey-900);
    background-color: var(--color-bg-base);
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-body2);
    line-height: var(--line-height-lg);
    letter-spacing: normal;
  }

  /* hide scrollbar but allow scrolling */
  body {
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      overflow-y: scroll; 
      
      &::-webkit-scrollbar {
          display: none; /* for Chrome, Safari, and Opera */
      }
  }

  body.modal-open {
    height: 100vh;
    overflow-y: hidden;
  }

  a { 
    color: inherit;
    text-decoration: none; 
  }

  // React Player Play Icon 
  .react-player__shadow {
    position: relative;
    width: 4.8rem !important;
    height: 4.8rem !important;
    border-radius: 50% !important;
    background: rgba(255, 255, 255, 0.7) !important;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      animation-delay: 0s;
      animation: pulsate 2s;
      animation-direction: forwards;
      animation-iteration-count: infinite;
      animation-timing-function: steps;
      opacity: 1;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  .react-player__play-icon {
    width: 2.4rem !important;
    height: 2.4rem !important;
    border-width: 1.2rem 0 1.2rem 2rem !important;
    border-color: transparent transparent transparent rgba(0,0,0, .9) !important;
    margin-left: 1.2rem !important;
  }

  ${down(breakpoints.sm)} {
    html {
      font-size: 87.5%;
      --font-size-h1: ${fontSizes[11]};
      --font-size-h2: ${fontSizes[10]};
      --font-size-h3: ${fontSizes[9]};
      --font-size-h4: ${fontSizes[7]};
      --font-size-h5: ${fontSizes[6]};
      --font-size-h6: ${fontSizes[5]};      
      --page-margin: 2rem;
    }
  }

  ${down(breakpoints.xs)} {
    html {
      font-size: 75%;
      --font-size-subtitle1: ${fontSizes[6]};
      --font-size-subtitle2: ${fontSizes[5]};
      --font-size-subtitle3: ${fontSizes[4]};
      --font-size-body1: ${fontSizes[5]};
      --font-size-body2: ${fontSizes[4]};
      --font-size-body3: ${fontSizes[3]};
      --font-size-button1: ${fontSizes[4]};
      --font-size-button2: ${fontSizes[3]};
      --font-size-allcaps: ${fontSizes[3]};
      --font-size-caption: ${fontSizes[3]};
      --page-margin: 1.5rem;
    }
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }

  .slide-enter {
    transform: translateX(100%);
  }
  .slide-enter-active {
    transform: translateX(0);
    transition: transform 300ms var(--ease-out);
  }
  .slide-exit {
    transform: translateX(0);
  }
  .slide-exit-active {
    transform: translateX(100%);
    transition: transform 300ms var(--ease-out);
  }

  .page-enter {
    overflow: hidden;
    transform: translateY(100%);
  }
  .page-enter-active {
    transform: translateY(0%);
    transition: transform 1000ms cubic-bezier(0.85, 0, 0.15, 1);
  }
  .page-exit {
    overflow: hidden;
    transform: translateY(0%);
  }
  .page-exit-active {
    transform: translateY(-100%);
    transition: transform 1000ms cubic-bezier(0.85, 0, 0.15, 1);
  }

  .in-scale img {
      animation: scaleUp 0.5s forwards;
  }

  .out-scale img {
      animation: scaleDown 1s forwards;
  }

  @keyframes scaleUp {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.02);
    }
  }

  @keyframes scaleDown {
    from {
      transform: scale(1.02);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes pulsate {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.3);
      opacity: 0;
    }
  }
`;

export default GlobalStyles;
