import React from 'react';
import { StyledHero } from './Hero.styled';
import { Headline, Greeting } from 'styles/typography';
import { CallToAction } from 'components/buttons';

const Hero = ({ sectionTitle, headline, btnText, linkTo, ...props }) => {
  return (
    <StyledHero {...props}>
      <Greeting>{sectionTitle}</Greeting>
      <Headline as='h1' level={2}>
        {headline}
      </Headline>
      {btnText ? (
        <CallToAction linkTo={linkTo} type='primary'>
          {btnText}
        </CallToAction>
      ) : null}
    </StyledHero>
  );
};

export default Hero;
