import styled, { css } from 'styled-components/macro';

export const Subtitle = styled.span`
  display: block;
  color: var(--color-grey-600);
  font-family: var(--font-family-title);
  font-size: ${(props) => `var(--font-size-subtitle${props.level})`};
  line-height: var(--line-height-md);
  letter-spacing: var(--letter-spacing-sm);

  ${(props) => {
    switch (props.level) {
      case 1:
        return subtitle1;
      case 2:
        return subtitle2;
      case 3:
        return subtitle3;
      default:
        return;
    }
  }}
`;

const subtitle1 = css`
  font-weight: var(--font-weight-medium);
`;
const subtitle2 = css`
  font-weight: var(--font-weight-medium);
`;
const subtitle3 = css`
  font-weight: var(--font-weight-medium);
`;
