import styled from 'styled-components/macro';
import { rgba } from 'polished';

const StyledInpageNavLink = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  margin-bottom: var(--space-xxs);

  background: ${rgba('#fff', 0)};
  color: var(--color-white);
  opacity: 0.7;
  cursor: pointer;

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }

  span {
    display: block;
    width: 0.4rem;
    height: 0.4rem;
    background: currentColor;
    border-radius: 0.4rem;
  }

  p {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 300ms ease;
    font-size: var(--font-size-body3);
    line-height: 1;

    ::after {
      position: absolute;
      bottom: 0;
      content: '';
      display: block;
      width: 0;
      height: 1px;
      background-color: #fff;
    }
  }

  &.open {
    p {
      opacity: 1;
      left: 1.2rem;
    }
  }

  &.active {
    opacity: 1;
    font-weight: var(--font-weight-medium);
    transition: all 200ms ease 250ms;

    span {
      transform: scale(1.4);
      transition: all 200ms ease 250ms;
    }
  }

  &:hover {
    opacity: 1;
    p {
      ::after {
        width: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }
`;

export default StyledInpageNavLink;
