import {
  TOGGLE_INPAGE_NAV,
  SET_ACTIVE_INPAGE_NAVLINK,
  CLEAR_ACTIVE_INPAGE_NAVLINK,
} from '../types';

const inpageNavReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_INPAGE_NAV:
      return {
        ...state,
        isVisible: !state.isVisible,
      };
    case SET_ACTIVE_INPAGE_NAVLINK:
      return {
        ...state,
        activeNavLink: action.payload,
      };
    case CLEAR_ACTIVE_INPAGE_NAVLINK:
      return {
        ...state,
        isVisible: false,
        activeNavLink: null,
      };
    default:
      return state;
  }
};

export default inpageNavReducer;
