import styled from 'styled-components/macro';
import theme from 'styles/theme';

const { zIndices, down, breakpoints } = theme;

export const StyledNavbar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndices.navbar};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.5rem;
  margin-left: var(--page-margin);
  margin-right: var(--page-margin);

  .menu-icon {
    z-index: ${zIndices.menuIcon};
    color: ${(props) => (props.showMenu ? 'white' : 'black')};
    cursor: pointer;
    width: 3rem;
    height: 3rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .navbar-logo {
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease;
  }

  .navbar-logo.hide {
    visibility: hidden;
    opacity: 0;
  }

  ${down(breakpoints.md)} {
    max-width: 100%;
  }
`;
