import React from 'react';
import styled from 'styled-components/macro';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { motion } from 'framer-motion';
import {
  Motion,
  fadeInUp as childVariants,
  parentVariants,
} from 'components/motion';
import { ModalImage, Image } from 'components/image';
import { Chip } from 'components/chips';
import { Container, Wrapper, Grid } from 'styles/layout';
import { Caption } from 'styles/typography';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledPrototyping = styled(Container)`
  .chip {
    margin-bottom: var(--space-sm);

    &-vol {
      color: #f94877;
    }

    &-org {
      color: #00aff0;
    }
  }

  ${Grid} {
    ${down(breakpoints.xs)} {
      grid-template-columns: 1fr;
    }

    &:first-of-type {
      margin-bottom: var(--space-lg);
    }
  }

  .test {
    &-card {
      align-self: stretch;
      background: var(--color-bg-base);
      padding: var(--space-sm);
      border: 1px solid var(--color-grey-200);
      border-radius: var(--radius-lg);
    }
    &-text {
      padding: var(--space-md);
    }
  }

  // Story map & Wireframes
  // figure {
  //   &:not(:last-of-type) {
  //     margin-bottom: var(--space-lg);
  //   }
  // }

  // ${Caption} {
  //   display: block;
  //   text-align: center;
  //   margin-top: var(--space-xxs);
  // }
`;

const Prototyping = ({ content }) => {
  const { heading, desc, subsections } = content;

  return (
    <StyledPrototyping>
      <Wrapper size='text' mb='var(--space-lg)'>
        <SectionTitle sectionTitle='Prototyping' heading={heading} />
        <SectionTextWithSubtitle desc={desc} />
      </Wrapper>
      {/* Test Results */}
      <Wrapper size='xl'>
        <Motion variants={parentVariants}>
          <Grid col={2} gap='lg'>
            <motion.div variants={childVariants} className='test-card'>
              <div className='test-text'>
                <Chip className='chip chip-vol'>Volunteer</Chip>
                <SectionTextWithSubtitle
                  heading={subsections.volunteer.signup.heading}
                  desc={subsections.volunteer.signup.desc}
                />
              </div>
              <Image
                path='volungo'
                name='prototype-vol-signup'
                format='png'
                sizes={[720, 1080, 1440]}
                alt='UI prototype to sign up'
              />
            </motion.div>
            <motion.div variants={childVariants} className='test-card'>
              <div className='test-text'>
                <Chip className='chip chip-vol'>Volunteer</Chip>
                <SectionTextWithSubtitle
                  heading={subsections.volunteer.preference.heading}
                  desc={subsections.volunteer.preference.desc}
                />
              </div>
              <Image
                path='volungo'
                name='prototype-vol-preference'
                format='png'
                sizes={[720, 1080, 1440]}
                alt='UI prototype to set personal preferences'
              />
            </motion.div>
          </Grid>
        </Motion>
        <Motion variants={parentVariants}>
          <Grid col={2} gap='lg'>
            <motion.div variants={childVariants} className='test-card'>
              <div className='test-text'>
                <Chip className='chip chip-org'>Organization</Chip>
                <SectionTextWithSubtitle
                  heading={subsections.organization.add.heading}
                  desc={subsections.organization.add.desc}
                />
              </div>
              <Image
                path='volungo'
                name='prototype-org-add'
                format='png'
                sizes={[720, 1080, 1440]}
                alt='UI prototype to add requirements for volunteers'
              />
            </motion.div>
            <motion.div variants={childVariants} className='test-card'>
              <div className='test-text'>
                <Chip className='chip chip-org'>Organization</Chip>
                <SectionTextWithSubtitle
                  heading={subsections.organization.invite.heading}
                  desc={subsections.organization.invite.desc}
                />
              </div>
              <Image
                path='volungo'
                name='prototype-org-invite'
                format='png'
                sizes={[720, 1080, 1440]}
                alt='UI prototype to invite suggested volunteers'
              />
            </motion.div>
          </Grid>
        </Motion>
      </Wrapper>
      {/* Story map */}
      {/* <Wrapper size='xl' as='figure'>
        <Motion variants={fadeInUp}>
          <ModalImage
            path='volungo'
            name='storymap-volunteer'
            format='jpg'
            sizes={[1440, 2160, 2880]}
            alt='Volunteer side storymap'
          />
          <Caption as='figcaption'>Volunteers story map</Caption>
        </Motion>
      </Wrapper>
      <Wrapper size='xl' as='figure'>
        <Motion variants={fadeInUp}>
          <ModalImage
            path='volungo'
            name='storymap-ngo'
            format='jpg'
            sizes={[1440, 2160, 2880]}
            alt='NGO side storymap'
          />
          <Caption as='figcaption'>NGO story map</Caption>
        </Motion>
      </Wrapper> */}
      {/* Wireframes */}
      {/* <Wrapper size='xl' as='figure'>
        <Motion variants={fadeInUp}>
          <ModalImage
            path='volungo'
            name='wireframe-volunteer'
            format='jpg'
            sizes={[1440, 2160, 2880]}
            alt='Volunteer side wireframes'
          />
          <Caption as='figcaption'>Volunteer side wireframes</Caption>
        </Motion>
      </Wrapper>
      <Wrapper size='xl' as='figure'>
        <Motion variants={fadeInUp}>
          <ModalImage
            path='volungo'
            name='wireframe-ngo'
            format='jpg'
            sizes={[1440, 2160, 2880]}
            alt='NGO side wireframes'
          />
          <Caption as='figcaption'>NGO side wireframes</Caption>
        </Motion>
      </Wrapper> */}
    </StyledPrototyping>
  );
};

export default Prototyping;
