import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Wrapper } from 'styles/layout';
import { Subtitle, Paragraph } from 'styles/typography';
import spacer from 'utils/spacer';

const StyledSectionTextWithSubtitle = styled(Wrapper)`
  ${Subtitle} {
    margin-bottom: var(--space-xs);
  }

  ${spacer}
`;

const SectionTextWithSubtitle = (props) => {
  return (
    <StyledSectionTextWithSubtitle size='text' {...props}>
      {props.heading && (
        <Subtitle as='h5' level={1}>
          {props.heading}
        </Subtitle>
      )}
      <Paragraph>{props.desc}</Paragraph>
    </StyledSectionTextWithSubtitle>
  );
};

SectionTextWithSubtitle.propTypes = {
  heading: PropTypes.string,
  desc: PropTypes.string.isRequired,
};

export default SectionTextWithSubtitle;
