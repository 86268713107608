import React, { useReducer } from 'react';
import inpageNavReducer from './inpageNavReducer';
import InpageNavContext from './InpageNavContext';
import {
  TOGGLE_INPAGE_NAV,
  SET_ACTIVE_INPAGE_NAVLINK,
  CLEAR_ACTIVE_INPAGE_NAVLINK,
} from '../types';

const InpageNavState = (props) => {
  const initialState = {
    isVisible: false,
    activeNavLink: null,
  };

  const [state, dispatch] = useReducer(inpageNavReducer, initialState);

  // Toggle Inpage Navigation
  const toggleInpageNav = () => {
    dispatch({ type: TOGGLE_INPAGE_NAV });
  };

  // Set Active Nav Link
  const setActive = (id) => {
    dispatch({ type: SET_ACTIVE_INPAGE_NAVLINK, payload: id });
  };

  // Clear Active Nav Link
  const clearActive = () => {
    dispatch({ type: CLEAR_ACTIVE_INPAGE_NAVLINK });
  };

  return (
    <InpageNavContext.Provider
      value={{
        isVisible: state.isVisible,
        activeNavLink: state.activeNavLink,
        toggleInpageNav,
        setActive,
        clearActive,
      }}
    >
      {props.children}
    </InpageNavContext.Provider>
  );
};

export default InpageNavState;
