import styled from 'styled-components/macro';
import spacer from 'utils/spacer';

export const Wrapper = styled.div`
  max-width: ${(props) =>
    props.size ? `var(--wrapper-${props.size})` : '100%'};
  margin: auto;

  ${spacer}
`;
