import React from 'react';
import DemoText from './DemoText';
import { SIZE_MOBILE, SIZE_DESKTOP } from 'constant/constant';
import { ReactVideo } from 'components/video';
import { Motion, fadeInUp } from 'components/motion';
import { Wrapper, Flex } from 'styles/layout';
import { StyledDemoVideo } from './DemoVideo.styled';

const DemoVideo = (props) => {
  const { type, category, path, video, heading, desc } = props;

  return (
    <StyledDemoVideo {...props}>
      <Wrapper size={type === 'mobile' ? 'lg' : 'xxl'}>
        <Flex gap='xl' className={`container-${type}`}>
          <Motion variants={fadeInUp}>
            <ReactVideo
              className={`video-${type}`}
              path={path}
              name={video}
              size={type === 'mobile' ? SIZE_MOBILE : SIZE_DESKTOP}
            />
          </Motion>
          <DemoText
            className={`text-${type}`}
            category={category || null}
            heading={heading}
            desc={desc}
          />
        </Flex>
      </Wrapper>
    </StyledDemoVideo>
  );
};

export default DemoVideo;
