import styled, { css } from 'styled-components/macro';
import { Card } from 'styles/card';
import { Headline, Paragraph } from 'styles/typography';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const styles = css`
  position: relative;
  overflow: hidden;

  .content {
    width: 100%;
    padding: var(--space-sm);

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      ${Headline} {
        margin-bottom: var(--space-xs);
      }

      svg {
        opacity: 0;
        font-size: var(--font-size-caption);
        margin-left: var(--space-xs);
        margin-bottom: var(--space-xs);
        color: var(--color-grey-700);
      }
    }

    ${Paragraph} {
      font-weight: var(--font-weight-light);
      line-height: var(--line-height-md);
      color: var(--color-grey-600);
    }

    &:hover {
      text-decoration: solid underline currentColor 1.5px;
      text-underline-position: auto;
      text-underline-offset: 3px;

      .title svg {
        transition: all 0.2s ease-in-out;
        opacity: 1;
      }
    }
  }

  .lock-icon {
    font-size: 0.875rem;
    position: absolute;
    color: #fff;
    opacity: 0.5;
    top: 1rem;
    right: 1rem;
    z-index: var(--z-index-lockIcon);
  }

  .image {
    border-radius: var(--radius-sm);
    overflow: hidden;
  }

  ${down(breakpoints.xs)} {
    .content {
      padding: var(--space-xs);
      padding-top: var(--space-sm);
    }

    ${Headline} {
      font-size: var(--font-size-h4);
      margin-bottom: 0 !important;
    }

    ${Paragraph} {
      font-size: var(--font-size-body1);
    }
  }
`;

export const StyledMoreWorksItem = styled(Card)`
  ${styles}
`;

export const StyledMoreWorksInProgress = styled(Card)`
  ${styles}

  .content > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      margin-bottom: var(--space-xs);
    }

    ${down(breakpoints.xs)} {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: var(--space-xxs);

      ${Headline} {
        margin-bottom: var(--space-xxs);
      }
    }
  }
`;
