import React from 'react';
import SummaryText from './SummaryText';
import { Motion, fadeIn, fadeInUp } from 'components/motion';
import { ReactVimeoVideo } from 'components/video';
import { Container, Wrapper } from 'styles/layout';

const videoVariants = {
  init: { opacity: 0 },
  anim: {
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: 0.5,
    },
  },
};

const Summary = ({ name, content, videoInfo }) => {
  const { challenge, solution } = content;
  const { id, caption } = videoInfo;

  return (
    <Container>
      <Wrapper size='text' mb='var(--space-xl)'>
        <Motion variants={fadeIn}>
          <SummaryText heading='Challenge' body={challenge} />
        </Motion>
        <Motion variants={fadeIn}>
          <SummaryText heading='Solution' body={solution} />
        </Motion>
      </Wrapper>
      <Wrapper size='lg'>
        <Motion variants={fadeIn}>
          <ReactVimeoVideo
            cover={{
              path: name,
              size: 1920,
              name: 'cover',
            }}
            videoId={id}
            caption={caption}
          />
        </Motion>
      </Wrapper>
    </Container>
  );
};

export default Summary;
