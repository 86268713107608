import styled from 'styled-components/macro';
import { Allcaps, Headline } from 'styles/typography';
import { Flex } from 'styles/layout';

export const StyledDemoText = styled.div`
  ${Flex} & {
    flex: 1;
  }

  ${Allcaps} {
    opacity: 0.7;
    margin-bottom: var(--space-xs);
  }

  ${Headline} {
    margin-bottom: var(--space-md);
  }
`;
