import React from 'react';
import styled from 'styled-components/macro';
import ReactPlayer from 'react-player';

const StyledReactVideo = styled.div``;

const ReactVideo = (props) => {
  const { path, name, size } = props;

  return (
    <StyledReactVideo {...props}>
      <ReactPlayer
        className='react-player'
        loop
        muted
        width='100%'
        height='100%'
        light={`${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${size}.jpg`}
        playsinline
        playing
        controls
        url={[
          {
            src: `${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${size}.webm`,
            type: 'video/webm',
          },
          {
            src: `${process.env.PUBLIC_URL}/assets/images/${path}/${name}-${size}.mp4`,
            type: 'video/mp4',
          },
        ]}
      />
    </StyledReactVideo>
  );
};

export default ReactVideo;
