const theme = {
  space: [
    0,
    '0.25rem',
    '0.5rem', // 2
    '0.75rem',
    '1rem', // 4
    '1.5rem',
    '2rem', // 6
    '2.5rem',
    '3rem', // 8
    '4rem',
    '6rem', // 10
    '9rem',
    '12rem', // 12
  ],
  breakpoints: ['36em', '57.5em', '75em', '87.5em'],
  fontSizes: [
    0,
    '0.75rem',
    '0.875rem', // 2
    '1rem',
    '1.125rem', // 4
    '1.3rem',
    '1.5rem', // 6
    '1.75rem',
    '2.125rem', // 8
    '2.4rem',
    '3rem', // 10
    '3.5rem',
    '6rem', // 12
  ],

  // Mobile (<576px)
  // h1: 3.75rem
  // h2: 3rem
  // h3: 2.4rem
  // h4: 2rem
  // h5: 1.5rem
  // h6: 1.25rem

  // fontSizes: {
  //   h1: '6rem',
  //   h2: '3.75rem',
  //   h3: '3rem',
  //   h4: '2.125rem',
  //   h5: '1.5rem',
  //   h6: '1.25rem',
  //   subtitle1: '1.25rem',
  //   subtitle2: '1.125rem',
  //   subtitle3: '1rem',
  //   body1: '1rem',
  //   body2: '0.875rem',
  //   allcaps: '0.875rem',
  //   caption: '0.75rem',
  //   button1: '1rem',
  //   button2: '0.875rem',
  // },
  fontWeights: [300, 400, 500, 600],
  fonts: {
    fontTitle: '"Figtree", sans-serif',
    fontBody: '"Figtree", sans-serif',

    systemFont:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  lineHeights: [1, 1.1, 1.3, 1.5, 1.75, 2, 2.5],
  letterSpacings: [
    0,
    '0.005em',
    '0.01em',
    '0.02em',
    '0.03em',
    '0.05em',
    '0.075em',
    '0.1em',
  ],
  colors: {
    black: '#000',
    white: '#fff',
    primary: '#111827',
    secondary: '',
    error: '#EF4444',
    warning: '#F59E0B',
    success: '#10B981',
    info: '#3B82F6',
    grey: {
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#9CA3AF',
      500: '#6B7280',
      600: '#4B5563',
      700: '#374151',
      800: '#1F2937',
      900: '#111827',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    text: {
      link: '#3a0ca3',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    background: {
      base: '#fff',
      paper: '#fafafa',
    },
    projects: {
      'absence-config': {
        base: '#0db5df',
      },
      'absence-rounding': {
        base: '#00c49a',
      },
      'customer-portal': {
        base: '#4e2098',
      },
      datavis: {
        base: '#4e2098',
      },
      volungo: {
        base: '#f94876',
      },
      yhteispot: {
        base: '#2bade1',
      },
      'cs-expert': {
        base: '#005eb8',
      },
    },
  },
  sizes: {},
  zIndices: {
    navbar: 1000,
    lockIcon: 500,
    inpageNav: 1000,
    menu: 1100,
    menuIcon: 1200,
    tooltip: 1300,
    modal: 1500,
  },
  borders: [
    0,
    '1px solid',
    '2px solid',
    '4px solid',
    '6px solid',
    '8px solid',
    '10px solid',
  ],
  radii: [0, '4px', '8px', '12px', '16px', '24px', '32px'],
  shadows: [
    'none',
    '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    '0 35px 60px -15px rgba(0, 0, 0, 0.3)',
  ],
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 100,
      short: 200,
      standard: 300,
      long: 500,
      longest: 700,
    },
  },
};

// Breakpoints Aliases
theme.breakpoints.xs = theme.breakpoints[0];
theme.breakpoints.sm = theme.breakpoints[1];
theme.breakpoints.md = theme.breakpoints[2];
theme.breakpoints.lg = theme.breakpoints[3];

// Space Aliases
theme.space.xxs = theme.space[1]; // 0.25rem
theme.space.xs = theme.space[2]; // 0.5rem
theme.space.sm = theme.space[4]; // 1rem
theme.space.md = theme.space[5]; // 1.5rem
theme.space.lg = theme.space[7]; // 2.5rem
theme.space.xl = theme.space[9]; // 4rem
theme.space.xxl = theme.space[10]; // 6rem

// Letter Spacing Aliases
theme.letterSpacings.xs = theme.letterSpacings[1]; // 0.005em
theme.letterSpacings.sm = theme.letterSpacings[2]; // 0.01em
theme.letterSpacings.md = theme.letterSpacings[4]; // 0.03em
theme.letterSpacings.lg = theme.letterSpacings[5]; // 0.05em
theme.letterSpacings.xl = theme.letterSpacings[7]; // 0.1em

// Media Query Helper functions
theme.up = (breakpoint) => `@media (min-width: calc(${breakpoint} + 0.005em))`;
theme.down = (breakpoint) => `@media (max-width: ${breakpoint})`;

export default theme;

/** BREAKPOINTS - MATERIAL UI
 * (https://mui.com/customization/breakpoints/#default-breakpoints)
 */
// xs, extra-small: 576px
// sm, small: 920px
// md, medium: 1200px
// lg, large: 1400px

/** LAYOUT (Screen size | Margin | Body | Layout Columns)
 * (https://material.io/design/layout/responsive-layout-grid.html#breakpoints)
 */
// Extra-small (phone portrait)
// 0-576 | 16 |	Scaling |	4

// Small (phone landscrape, tablet portrait)
// 577-920 | 32 |	Scaling |	8

// Medium (tablet lanscape)
// 921-1200 |	48 |	Scaling |	12

// Large (laptop)
// 1201-1400 | Scaling | 1140 | 12

// Extra-Large (desktop)
// 1400+ | Scaling | 1320 |	12
