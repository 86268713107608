import React from 'react';
import styled from 'styled-components/macro';
import { BsGeoAltFill } from 'react-icons/bs';
import { FaPaperPlane, FaLinkedinIn, FaGithub } from 'react-icons/fa';
import { StyledLink } from 'styles/link';
import { Helmet } from 'react-helmet-async';
import Page from 'components/container/Page';
import { Image } from 'components/image';
import { CallToAction } from 'components/buttons';
import Footer from 'templates/footer/Footer';
import Hero from 'templates/hero/Hero';
import { Paragraph } from 'styles/typography';
import { Container, Flex } from 'styles/layout';
import { ExternalLink } from 'styles/link';
import theme from 'styles/theme';

const { space, down, breakpoints } = theme;

const StyledAbout = styled.main`
  margin: auto;
  margin-bottom: var(--space-xxl);
  max-width: var(--wrapper-xl);

  .profile {
    flex: 0 0 16rem;

    img {
      border-radius: 9999px;
      display: block;
      width: 100%;
      aspect-ratio: 2/3;
      object-fit: cover;
    }

    ul {
      margin-top: var(--space-sm);
      list-style: none;
      text-align: center;
      color: inherit;

      li {
        line-height: 1;
        font-weight: var(--font-weight-light);
        font-size: var(--font-size-body2);
        &:not(:last-of-type) {
          margin-bottom: var(--space-xs);
        }
      }
    }

    .name {
      font-weight: var(--font-weight-medium);
    }

    .location {
      display: inline-flex;
      align-items: center;
      color: var(--color-grey-500);
      gap: var(--space-xxs);
    }

    .contacts {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--space-md);
      color: inherit;
      margin-top: var(--space-xs);

      svg {
        opacity: 1;
        margin-bottom: 0;

        &:hover {
          transition: all 0.3s ease-in-out;
          opacity: 0.7;
        }
      }
    }
  }

  ${Paragraph} {
    margin-bottom: var(--space-sm);

    &:last-of-type {
      margin-bottom: var(--space-md);
    }
  }

  ${Flex} {
    gap: ${space[10]};
  }

  ${down(breakpoints.md)} {
    max-width: var(--wrapper-lg);

    ${Flex} {
      gap: ${space[8]};
    }

    .profile {
      flex: 0 0 14rem;
    }
  }

  ${down(breakpoints.xs)} {
    header {
      padding-bottom: 6rem;
    }

    ${Flex} {
      flex-direction: column-reverse;
      align-items: start;
      gap: ${space[4]};
    }

    .profile {
      display: grid;
      grid-template-columns: min-content max-content;
      align-items: center;
      gap: var(--space-md);

      img {
        width: 10rem;
        aspect-ratio: 1;
      }

      ul {
        li {
          font-size: var(--font-size-body1);

          &.name {
            text-align: left;
            font-size: var(--font-size-h5);
            margin-bottom: var(--space-md);
          }

          &.contacts {
            justify-content: start;
            margin-bottom: var(--space-sm);
          }
        }
      }
    }
  }
`;

const About = () => {
  return (
    <Page>
      <Helmet>
        <title>About | Yuvin Ha</title>
        <meta
          name='description'
          content='Yuvin Ha is a UX designer with 6+ years of experience, an HCI researcher, and a self-taught developer with front-end skills, currently based in Munich, Germany.'
        />
      </Helmet>
      <Container>
        <StyledAbout>
          <Hero
            className='header'
            sectionTitle='Product designer@Personio'
            headline="I'm currently transforming HR experiences for European SMEs"
            pb={10}
          />
          <Flex alignItems='start' gap='xl'>
            <section className='text'>
              <Paragraph level={1}>
                I'm an interdisciplinary designer working at the intersection of
                technology and design. Throughout my career, I've had the
                opportunity to work for leading companies in different
                industries, helping them create products and services that make
                our lives better. Currently, I'm working as a Product Designer
                at{' '}
                <ExternalLink
                  to={{ pathname: 'https://www.personio.com/' }}
                  target='_blank'
                >
                  Personio
                </ExternalLink>
                , developing scalable solutions in compliance heavy domains,
                absence and organization management.{' '}
              </Paragraph>
              <Paragraph level={1}>
                Before transitioning to a hyper-growth startup, I worked for
                established companies. At{' '}
                <ExternalLink
                  to={{ pathname: 'https://www.mogroup.com/' }}
                  target='_blank'
                >
                  Metso Outotec
                </ExternalLink>{' '}
                , I led design for a new customer self-service portal and data
                visualization guidelines/components of the design system as a UX
                Designer. Previously, as a UX Designer at{' '}
                <ExternalLink
                  to={{ pathname: 'https://www.lg.com/global' }}
                  target='_blank'
                >
                  LG Electronics
                </ExternalLink>{' '}
                UX lab, I engaged in identifying and addressing the needs of the
                consumer to create the next generation of smart TV, smartwatch,
                and home appliance experiences.
              </Paragraph>
              {/* <Paragraph level={1}>
                It comes natural to me to apply Systems Thinking to how I design
                and build interfaces/experiences, which makes me thrive at
                identifying patterns and surfacing the underlying structures. I
                feel comfortable with uncertainty and ambiguity. I do care about
                consistency and efficiency Passion for providing a cohesive user
                experience to users I have a good attention to details,
                producing high-quality design work having future of product in
                mind / impact on the product portfolio, holistic experience with
                brand as a whole I proactively explore new design directions and
                experiment with different approaches
              </Paragraph> */}
              <Paragraph level={1}>
                With a growth and learning mindset, I'm always trying to go
                beyond my comfort zone and learning something new. Over the past
                few years, I taught myself to code and front-end web development
                and created several interactive art installations as well as
                websites/web applications including this one built with React. I
                also have lived in several countries for studying and working,
                enjoying new challenges/experiences and cultural diversity.
              </Paragraph>
              <Paragraph level={1}>
                Have an interesting project? Let's talk.
              </Paragraph>
              <CallToAction
                type='primary'
                isExternal
                to={{
                  pathname:
                    'https://drive.google.com/file/d/1RPIa8n9vlQGLt_QNssGcCDKIr7OPu7nj/view',
                }}
                target='_blank'
              >
                View resume
              </CallToAction>
            </section>
            <div className='profile'>
              <Image
                path='about'
                name='profile-square'
                format='jpg'
                sizes={[300, 600, 900]}
                alt='profile'
              />
              <ul>
                <li className='name'>Yuvin Ha</li>
                <li className='location'>
                  <BsGeoAltFill />
                  Munich, Germany
                </li>
                <li className='contacts'>
                  <StyledLink as='a' href='mailto:ha.yuvin@gmail.com'>
                    <FaPaperPlane />
                  </StyledLink>
                  <StyledLink
                    to={{
                      pathname: 'https://www.linkedin.com/in/yuvinha',
                    }}
                    target='_blank'
                  >
                    <FaLinkedinIn />
                  </StyledLink>
                  <StyledLink
                    to={{ pathname: 'https://github.com/yuvinha' }}
                    target='_blank'
                  >
                    <FaGithub />
                  </StyledLink>
                </li>
              </ul>
            </div>
          </Flex>
        </StyledAbout>
      </Container>
      <Footer />
    </Page>
  );
};

export default About;
