import { v4 as uuidv4 } from 'uuid';

export const works = {
  featured: [
    {
      id: uuidv4(),
      title: 'International absence setting design',
      name: 'absence-config',
      description:
        'Design a scalable solution to help HR managers set up and manage international absence policies',
      category: 'Product Design',
      date: '2022',
      isProtected: true,
      isReady: true,
      isExternal: true,
      isFeatured: true,
      link: 'https://drive.google.com/file/d/1iWVuLJCEYQg8Eyo68QLItIQ87ZEw-ygK/view?usp=sharing',
    },
    {
      id: uuidv4(),
      title: 'Absence rounding rule design',
      name: 'absence-rounding',
      description:
        "Design rounding rules to calculate employees' annual leave entitlement in compliance with regulations",
      category: 'Product Design',
      date: '2023',
      isProtected: true,
      isReady: true,
      isExternal: true,
      isFeatured: false,
      link: 'https://drive.google.com/file/d/1EBiiiZr0YA7YBQx1kRFuiLBMGN8x7g_7/view?usp=sharing',
    },
    {
      id: uuidv4(),
      title: 'Customer self-service portal',
      name: 'customer-portal',
      description:
        'Develop an end-user portal that provides unified access to cloud services and necessary information',
      category: 'UX/UI Design',
      date: '2021',
      isProtected: true,
      isReady: true,
      isExternal: true,
      isFeatured: false,
      link: 'https://drive.google.com/file/d/1lJItvLTVAoqpeRpafDLeOrtJutPa3_-4/view?usp=sharing',
    },
    {
      id: uuidv4(),
      title: 'VolunGO',
      name: 'volungo',
      description:
        'Develop a dual platform for intelligent matching of volunteers and nonprofit organizations',
      category: 'Product Design',
      date: '2021',
      isProtected: false,
      isReady: true,
      isExternal: false,
      isFeatured: true,
      link: '',
    },

    {
      id: uuidv4(),
      title: 'Yhteispot',
      name: 'yhteispot',
      description:
        'Design a collaborative service model to empower citizens for responsible leisure activities',
      category: 'Concept Design',
      date: '2021, 2018',
      isProtected: false,
      isReady: true,
      isExternal: false,
      isFeatured: false,
      link: '',
    },
    {
      id: uuidv4(),
      title: 'CS eXpert',
      name: 'cs-expert',
      description:
        'Design a tool to support customer service agents to develop and specialize themselves',
      category: 'Concept Design',
      date: '2018',
      isProtected: false,
      isReady: true,
      isExternal: false,
      isFeatured: false,
      link: '',
    },
    {
      id: uuidv4(),
      title: 'M:O Charts',
      name: 'datavis',
      description:
        'Develop Metso Outotec data visualization guideline and chart components',
      category: 'Design System',
      date: '2021',
      isProtected: true,
      isReady: false,
      isExternal: false,
      isFeatured: false,
      link: '',
    },
  ],
  moreworks: [
    {
      id: uuidv4(),
      title: 'Family Now',
      name: 'family-now',
      description:
        'Designing a IoT-based service concept in the hyper-connected world',
      category: 'Service Concept Design',
      date: '2014',
      isProtected: true,
      isReady: true,
      isExternal: true,
      isFeatured: false,
      link: '',
    },
    {
      id: uuidv4(),
      title: 'Live Menu',
      name: 'live-menu',
      description:
        'UI/UX design of smart TV app for simplified content discovery',
      category: 'UX/UI Design',
      date: '2013',
      isProtected: true,
      isReady: true,
      isExternal: true,
      isFeatured: false,
      link: '',
    },
    {
      id: uuidv4(),
      title: 'New Black',
      name: 'new-black',
      description: 'UI/UX design for the next LG smart TV platform',
      category: 'UX/UI Design',
      date: '2012',
      isProtected: true,
      isReady: true,
      isExternal: true,
      isFeatured: false,
      link: '',
    },
    {
      id: uuidv4(),
      title: 'Shadow Zoo',
      name: 'shadow-zoo',
      description: 'Interactive art where you play with your hand shadows',
      category: 'Interaction Design',
      date: '2019',
      isProtected: false,
      isReady: true,
      isExternal: false,
      isFeatured: false,
      link: '',
    },
    {
      id: uuidv4(),
      title: 'Umbrella Forecaster',
      name: 'umbrella',
      description:
        'Interactive, physical data visualization of a chance of rain',
      category: 'Interaction Design',
      date: '2019',
      isProtected: false,
      isReady: true,
      isExternal: false,
      isFeatured: false,
      link: '',
    },
    {
      id: uuidv4(),
      title: 'Your Heartbeat',
      name: 'heartbeat',
      description:
        'Interactive art where you feel your heartbeat with eyes and ears',
      category: 'Interaction Design',
      date: '2018',
      isProtected: false,
      isReady: true,
      isExternal: false,
      isFeatured: false,
      link: '',
    },
  ],
};
