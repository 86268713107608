import { TAG_WELL } from 'constant/constant';

const csExpert = {
  header: {
    heading: 'CS eXpert',
    subheading:
      'Design a tool to support customer service agents to develop and specialize themselves',
    hero: {
      path: 'cs-expert',
      name: 'hero',
      format: 'jpg',
      sizes: [1440, 2160, 2880],
      alt: 'cs-expert hero image',
    },
    info: {
      timeframe: 'Feb - Apr 2021 (7w)',
      client: 'Kone',
      role: 'Research, Analysis, Ideation, Concept development, User stories, UX/UI design, Prototyping, User testing',
      team: 'Jiyoung Son, Tilda Jyräsalo',
    },
  },
  summary: {
    challenge:
      'Customer service agents (CS agents) interact with internal and external customers and provide information about products/services as well as handling and resolving customer complaints. Thus, it’s an important part of the promise the company makes to its customers. In this project, we were asked to identify pain points faced by CS agents on the way to deliver great customer service. Our user research uncovered that although CS Agents want to develop themselves and become experts in the company using their own competence, tools and internal supports are not offered enough.',
    solution:
      'We proposed CS eXpert, an online tool designed to support CS agents to develop and specialize themselves. It enables customer service agents to create the personalized career profile and development path and make them more visible and accessible, thereby helping them to fulfill self-actualization.',
  },
  demo: {
    signup: {
      heading: 'Five steps to create your personalized career profile',
      desc: 'CS agents can easily create their personalized career profile by answering the questions about their personalities, current skills, preference for tasks, and a future goal step by step.',
    },
    profile: {
      heading:
        'Personalized career profile that presents your unique skill set',
      desc: 'Personalized profile shows your specialities in an intuitive and visually pleasant way. It also provides information of what skills are lacking, suggesting how to develop them.',
    },
    development: {
      heading:
        'Unified platform for learning and development to become a specialist',
      desc: 'All learning and self-development processes are presented and managed in one place, so CS agents and their manager can easily keep track of the progress.',
    },
  },
  highlight: {
    hmw: {
      heading: 'Reframed project goal',
      desc: '"How might we provide great meaningful experiences for customer service agents as well as diminish their pain points?"',
    },
    product:
      'CS eXpert supports customer service agents to develop themselves and become a specialist.',
  },
  methodology: {
    heading: 'Experience goal-driven design',
    desc: 'In this project, we adopted “Experience goal-driven design” approach, in which the ultimate goal of design is to create enjoyable and meaningful experiences. We utilized experience goals (Xgoal) throughout the entire Double Diamond design process from exploration to design to evaluation.',
    reference:
      'Xgoal is a conceptual tool that concretizes “the intended momentary emotion or the emotional relationship/bond that a person has towards the designed product or service” (Lu and Loto, 2014).',
  },
  research: {
    heading: 'Explore the problem space',
    subsections: [
      {
        heading: 'Understand the context and who we design for',
        desc: 'Before conducting user research, we first developed our understanding of the brief and the context. By having a couple of meetings with the client, we confirmed the role of CS agents has been increasing as customer touchpoints become diversified . We also learned that how CS agents are functioning as a mediator between internal and external stakeholders.',
      },
      {
        heading: 'Empathize with actual users',
        desc: 'With a better understanding of the nature of CS agents, we interviewed six employees—5 CS agents and the head of the customer care center—and observed how they actually work. In the interview, we had a 5-minute activity related to Xgoals, where we asked participants to choose 3 out of 50 emotions that they ultimately want to experience through their work.',
      },
    ],
  },
  analysis: {
    heading: 'Define a major challenge to address',
    subsections: [
      {
        heading: 'Set what experience to design for',
        desc: 'By comparing the Xgoals that CS agents chose with the ones that we hypothesized using the two-dimensional matrix, we found out three experience goals that CS agents are seeking for: Self-actualization, Everyday Fulfillment, and Challenging.',
      },
      {
        heading: 'Identify pain points and areas of opportunity',
        desc: 'By reviewing user interviews and analyzing them through Affinity diagramming, we identified 15 key findings and insights under 8 themes which emphasizes different aspects of CS agents’ experience as seen in the image below.',
      },
      {
        heading: 'Narrow down the insights',
        desc: "With the result of analysis, we prioritized the challenges we are going to address. The client asked us to focus on the Finland-specific insights that were not pointed out in other countries. So, we decided to concentrate on 'Self-atualization' and 'Development & Learning', formulating the user stories that we are going to tackle.",
      },
    ],
    insights: {
      'self-actualization': [
        {
          insight:
            'As a CS agent, when there isn’t enough challenge, I’m not very motivated. I want the work to be slightly challenging, so that I can bring out my own ideas and influence more.',
          quotes: [
            {
              quote:
                'I enjoy my work more if there are challenges, especially when something new comes.',
              quotedBy: '',
            },
            {
              quote:
                'When it comes to self-actualization, I don’t feel that this job is providing many opportunities.',
              quotedBy: '',
            },
          ],
        },
      ],
      'development-learning': [
        {
          insight:
            'As a CS agent, when there is something I don’t know, I want to learn how to do it. Sometimes, I ask colleagues to show me the way to manage an issue, so that I can deal with it myself next time while developing myself.',
          quotes: [
            {
              quote:
                'I already knew that KONE had a good reputation.However, I wish that there had been great possibilities I want something extra to proceed career-wise.',
              quotedBy: '',
            },
            {
              quote: 'Only answering the phone is too boring for me.',
              quotedBy: '',
            },
          ],
        },
      ],
    },
  },
  concept: {
    heading: 'Generate design concepts',
    desc: 'We first generated 54 initial ideas in one-day ideation workshop using Brainwriting1 technique, prioritizing the ideas by mapped them on the Value/Effort matrix. As a result, we narrowed down to three most promising ideas and further developed them by creating a user scenarios, service flow charts, and paper prototypes.',
    reference:
      'Brainwriting an idea generation technique where members write ideas onto cards, pass them around the team, and build on the ideas of others.',
  },
  prototyping: {
    heading: 'Prototype & Evaluate the solutions with users',
    desc: 'We prototyped two concepts after the discussion with the client. Using the clickable hi-fi prototypes, We evaluated both pragmatic and hedonic value and the attractiveness of the solutions with 3 participants: two CS agents and the head of the customer care center. As a result, both concepts were well-appreciated and thought as a useful tool to accomplish the experience goals. We also got constructive comments and suggestions to improve towards the final design.',
    test: [
      {
        heading: 'Understand',
        desc: 'Introduce the overall concept and service flow',
      },
      {
        heading: 'Experience',
        desc: 'Ask to perform the assigned tasks using a prototype',
      },
      {
        heading: 'Evaluate',
        desc: 'Ask to evaluate the concept and discuss details',
      },
    ],
  },
  reflection: [
    {
      heading: 'Experience goals can improve the design process',
      desc: 'We learned experience-goal driven design by practicing it throughout the process. Experience goals helped us maintaining our focus and decision making. Also, it enabled us to uncover the emotional side of CS agents’ needs that were neglected in other precedent research.',
      tags: [TAG_WELL],
    },
    {
      heading: 'How to evaluate the emotional side of an interactive product',
      desc: 'We used AttrakDiff for the measurement in the user testing. By modifying this, we were able to evaluate both the pragmatic and the hedonic quality of our solution as well as whether our solution helps users to achieve their experience goals.',
      tags: [TAG_WELL],
    },
    {
      heading: 'Create prototypes at a different level of fidelity',
      desc: 'We developed our ideas by sketching with a pen and paper, drawing wireframes, and creating paper and hi-fi clickable prototypes. They helped us to quickly translate high-level concepts into tangible artifacts and test ideas quickly and efficiently.',
      tags: [TAG_WELL],
    },
  ],
};

export default csExpert;
