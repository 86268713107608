import React from 'react';
import styled from 'styled-components/macro';
import DemoVideo from 'patterns/demo/DemoVideo';
import { Container } from 'styles/layout';

const StyledDemo = styled(Container)`
  margin-top: var(--space-xxl);
`;

const Demo = ({ content: { volunteer, organization }, name }) => {
  return (
    <StyledDemo>
      <DemoVideo
        type='mobile'
        category='volunteer'
        path={name}
        video='vol-signup'
        heading={volunteer.signup.heading}
        desc={volunteer.signup.desc}
      />
      <DemoVideo
        type='mobile'
        category='volunteer'
        path={name}
        video='vol-preference'
        heading={volunteer.preference.heading}
        desc={volunteer.preference.desc}
      />
      <DemoVideo
        type='mobile'
        category='volunteer'
        path={name}
        video='vol-favorites'
        heading={volunteer.favorites.heading}
        desc={volunteer.favorites.desc}
      />
      <DemoVideo
        type='mobile'
        category='volunteer'
        path={name}
        video='vol-join'
        heading={volunteer.join.heading}
        desc={volunteer.join.desc}
      />
      <DemoVideo
        type='desktop'
        category='organization'
        path={name}
        video='org-signup'
        heading={organization.signup.heading}
        desc={organization.signup.desc}
      />
      <DemoVideo
        type='desktop'
        category='organization'
        path={name}
        video='org-project'
        heading={organization.project.heading}
        desc={organization.project.desc}
      />
    </StyledDemo>
  );
};

export default Demo;
