// AUTH
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// INPAGE NAV
export const SET_ACTIVE_INPAGE_NAVLINK = 'SET_ACTIVE_INPAGE_NAVLINK';
export const CLEAR_ACTIVE_INPAGE_NAVLINK = 'CLEAR_ACTIVE_INPAGE_NAVLINK';
export const TOGGLE_INPAGE_NAV = 'TOGGLE_INPAGE_NAV';

// MODAL
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
