import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Grid, Wrapper } from 'styles/layout';
import { Motion } from 'components/motion';
import { ModalImage } from 'components/image';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledGallery = styled(Wrapper)`
  img {
    height: 100%;
  }

  & > ${Grid} {
    ${down(breakpoints.xs)} {
      grid-template-columns: 1fr;
    }
  }
`;

const parentVariants = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.4,
    },
  },
};

const childVariants = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.5,
      ease: 'easeIn',
    },
  },
};

const Gallery = (props) => {
  const { path } = props;

  return (
    <StyledGallery>
      <Motion variants={parentVariants}>
        <Grid col={2} gap='xs'>
          <motion.div variants={childVariants}>
            <ModalImage
              modalSize='xl'
              path={path}
              name='scene-lg'
              format='jpg'
              sizes={[960, 1440, 1920]}
              alt='audience playing with hand shadow'
            />
          </motion.div>
          <motion.div variants={childVariants}>
            <ModalImage
              modalSize='xl'
              path={path}
              name='tool'
              format='jpg'
              sizes={[960, 1440, 1920]}
              alt='Tool to capture hand shadow'
            />
          </motion.div>
          <motion.div variants={childVariants}>
            <ModalImage
              modalSize='xl'
              path={path}
              name='screen'
              format='jpg'
              sizes={[960, 1440, 1920]}
              alt='Captured image of the screen showing hand shadow puppets'
            />
          </motion.div>
          <motion.div variants={childVariants}>
            <Grid col={2} gap='xs'>
              {Array.from({ length: 4 }).map((val, idx) => (
                <ModalImage
                  modalSize='xl'
                  key={idx}
                  path={path}
                  name={`scene-${idx + 1}`}
                  format='jpg'
                  sizes={[720, 1080, 1440]}
                  alt={`close shot of an audience playing with hand shadow ${
                    idx + 1
                  }`}
                />
              ))}
            </Grid>
          </motion.div>
        </Grid>
      </Motion>
    </StyledGallery>
  );
};

export default Gallery;
