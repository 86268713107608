import React, { useContext } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import InpageNavContext from 'context/inpage-nav/InpageNavContext';
import StyledInpageNavLink from './InpageNavLink.styled';

const InpageNavLink = ({ name, id }) => {
  const { isVisible, activeNavLink, setActive } = useContext(InpageNavContext);

  const handleClick = () => {
    setActive(id);

    document.getElementById(id).scrollIntoView({
      behavior: 'smooth', // gives an ease-in-out effect to our scroll
    });
  };

  return (
    <StyledInpageNavLink
      className={`${activeNavLink === id && 'active'} ${isVisible && 'open'}`}
      onClick={handleClick}
    >
      {id === 'top' ? <FaArrowUp /> : <span>&nbsp;</span>}
      <p>{name}</p>
    </StyledInpageNavLink>
  );
};

export default InpageNavLink;

/**
 * https://medium.com/geekculture/scrollable-single-page-site-navigation-with-react-custom-hooks-4e7af716f6b1
 */
