import styled from 'styled-components/macro';

export const StyledChip = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-xxs) var(--space-xs);
  color: ${(props) =>
    props.color ? `var(--color-${props.color}-text)` : 'var(--color-grey-500)'};
  background: ${(props) =>
    props.color ? `var(--color-${props.color}-bg)` : 'var(--color-white)'};
  font-size: var(--font-size-caption);
  line-height: 1;
  border-radius: 9999px;
  border: 1px solid currentColor;

  &:not(:last-of-type) {
    margin-right: var(--space-xs);
  }
`;
