import React, { Fragment } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components/macro';
import { AnimatePresence } from 'framer-motion';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import Modal from 'components/modal/Modal';
import AuthState from 'context/auth/AuthState';
import InpageNavState from 'context/inpage-nav/InpageNavState';
import ModalState from 'context/modal/ModalState';
import Navbar from 'templates/navigation/navbar/Navbar';
import Home from 'pages/home/Home';
import About from 'pages/about/About';
import Volungo from 'pages/projects/volungo/Volungo';
import Yhteispot from 'pages/projects/yhteispot/Yhteispot';
import CsExpert from 'pages/projects/cs-expert/CsExpert';
import ShadowZoo from 'pages/projects/shadow-zoo/ShadowZoo';
import Umbrella from 'pages/projects/umbrella/Umbrella';
import Heartbeat from 'pages/projects/heartbeat/Heartbeat';
import theme from 'styles/theme';
import GlobalStyles from 'styles/Global';

const App = () => {
  const location = useLocation();
  useGoogleAnalytics();

  return (
    <AuthState>
      <InpageNavState>
        <ModalState>
          <HelmetProvider>
            <ThemeProvider theme={theme}>
              <Fragment>
                <GlobalStyles />
                <Navbar />
                <Modal />
                <AnimatePresence initial={false}>
                  <Switch location={location} key={location.key}>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/about' component={About} />
                    <Route exact path='/volungo' component={Volungo} />
                    <Route exact path='/yhteispot' component={Yhteispot} />
                    <Route exact path='/cs-expert' component={CsExpert} />
                    <Route exact path='/shadow-zoo' component={ShadowZoo} />
                    <Route exact path='/umbrella' component={Umbrella} />
                    <Route exact path='/heartbeat' component={Heartbeat} />
                  </Switch>
                </AnimatePresence>
              </Fragment>
            </ThemeProvider>
          </HelmetProvider>
        </ModalState>
      </InpageNavState>
    </AuthState>
  );
};

export default App;
