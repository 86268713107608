import React from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import { motion } from 'framer-motion';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { Motion, fadeInUp, parentVariants } from 'components/motion';
import { ModalImage } from 'components/image';
import { Container, Wrapper, Grid } from 'styles/layout';
import { Caption } from 'styles/typography';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledSolution = styled(Container)`
  .vision img {
    border: 1px solid ${rgba('#f94876', 0.2)};
  }

  .business-model img {
    border: 1px solid var(--color-grey-200);
  }

  ${Caption} {
    margin-top: var(--space-xs);
  }

  ${down(breakpoints.xs)} {
    ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

const Solution = ({ content }) => {
  const { heading, desc } = content;

  return (
    <StyledSolution>
      <Wrapper size='text' mb='var(--space-lg)'>
        <SectionTitle sectionTitle='solution' heading={heading} />
        <SectionTextWithSubtitle desc={desc} />
      </Wrapper>
      <Wrapper size='xl'>
        <Motion variants={parentVariants}>
          <Grid col={2} gap='lg'>
            <motion.figure variants={fadeInUp} className='vision'>
              <ModalImage
                modalSize='lg'
                path='volungo'
                name='vision'
                format='png'
                sizes={[800, 1200, 1600]}
                alt='Volungo concept diagram'
              />
              <Caption as='figcaption' textAlign='center'>
                Volungo concept diagram
              </Caption>
            </motion.figure>
            <motion.figure variants={fadeInUp} className='business-model'>
              <ModalImage
                as='figure'
                modalSize='lg'
                path='volungo'
                name='business-model'
                format='png'
                sizes={[800, 1200, 1600]}
                alt='Volungo business model'
              />
              <Caption as='figcaption' textAlign='center'>
                Volungo business model
              </Caption>
            </motion.figure>
          </Grid>
        </Motion>
      </Wrapper>
    </StyledSolution>
  );
};

export default Solution;
