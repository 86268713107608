import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Motion = ({ children, variants, ...props }) => {
  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start('anim');
    } else {
      controls.start('init');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial='init'
      animate={controls}
      variants={variants}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default Motion;
