import React, { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { StyledModal } from './Modal.styled';
import ModalContext from 'context/modal/ModalContext';
// import { IoCloseOutline } from 'react-icons/io5';

const backdropVariants = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
    },
  },
};

const Modal = () => {
  const { isOpen, modalContent, closeModal } = useContext(ModalContext);

  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <StyledModal
          as={motion.div}
          onClick={closeModal}
          variants={backdropVariants}
          initial='init'
          animate='anim'
          exit='init'
        >
          {/* <IoCloseOutline onClick={closeModal} /> */}
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            {modalContent}
          </div>
        </StyledModal>
      )}
    </AnimatePresence>
  );
};

export default Modal;
