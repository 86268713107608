import React from 'react';
import styled from 'styled-components/macro';
import { Subtitle } from 'styles/typography';

const StyledDataDisplay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-grey-100);
  color: var(--color-grey-700);
  border-radius: var(--radius-sm);
  padding: var(--space-lg) var(--space-xs);
  padding-bottom: var(--space-md);
  text-align: center;

  ${Subtitle} {
    color: var(--color-grey-500);
    opacity: 0.7;
  }
  p {
    font-size: 4rem;
    font-weight: var(--font-weight-regular);
  }
`;

const DataDisplay = ({ title, data }) => {
  return (
    <StyledDataDisplay>
      <Subtitle level={2}>{title}</Subtitle>
      <p>{data}</p>
    </StyledDataDisplay>
  );
};

export default DataDisplay;
