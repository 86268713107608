import React from 'react';
import { StyledReflectionItem } from './Reflectionitem.styled';
import { Subtitle, Paragraph, Caption } from 'styles/typography';

const ReflectionItem = (props) => {
  const { heading, desc, tags } = props;
  return (
    <StyledReflectionItem {...props}>
      <Subtitle as='strong' level={2}>
        {heading}
      </Subtitle>
      <Paragraph>{desc}</Paragraph>
      {tags && tags.map((tag, idx) => <Caption key={idx}>#{tag}</Caption>)}
    </StyledReflectionItem>
  );
};

export default ReflectionItem;
