import React from 'react';
import styled from 'styled-components/macro';
import DemoVideo from 'patterns/demo/DemoVideo';
import { Container } from 'styles/layout';

const StyledDemo = styled(Container)`
  margin-top: var(--space-xxl);
`;

const Demo = ({ name, content }) => {
  const { search, view, add } = content;

  return (
    <StyledDemo>
      <DemoVideo
        type='mobile'
        path={name}
        video='mobile-search'
        heading={search.heading}
        desc={search.desc}
      />
      <DemoVideo
        type='mobile'
        path={name}
        video='mobile-view'
        heading={view.heading}
        desc={view.desc}
      />
      <DemoVideo
        type='mobile'
        path={name}
        video='mobile-add'
        heading={add.heading}
        desc={add.desc}
      />
    </StyledDemo>
  );
};

export default Demo;
