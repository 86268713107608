import styled from 'styled-components/macro';

export const Greeting = styled.span`
  position: relative;
  display: inline-block;
  font-weight: var(--font-weight-light);
  font-family: var(--font-family-title);
  font-size: var(--font-size-h5);
  line-height: 1;
  letter-spacing: var(--letter-spacing-sm);
  color: var(--color-grey-500);
`;
