import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Motion, fadeIn, fadeInUp, parentVariants } from 'components/motion';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { ModalImage } from 'components/image';
import { Container, Wrapper, Grid } from 'styles/layout';
import { Caption } from 'styles/typography';

const StyledResearch = styled(Container)`
  ${Caption} {
    margin-top: var(--space-xs);

    span {
      font-weight: var(--font-weight-bold);
    }
  }
`;

const Research = ({ content }) => {
  const { heading, subsections } = content;

  return (
    <StyledResearch>
      <Wrapper size='text' mb='var(--space-xl)'>
        <SectionTitle sectionTitle='research' heading={heading} />
        <SectionTextWithSubtitle
          heading={subsections[0].heading}
          desc={subsections[0].desc}
          mb='var(--space-md)'
        />
        <Motion variants={fadeIn}>
          <figure>
            <ModalImage
              modalSize='xl'
              path='yhteispot'
              name='challenge'
              format='jpg'
              sizes={[800, 1200, 1600]}
              alt='Evidence of an unauthorized fire along Rantaraitti'
            />
            <Caption as='figcaption' textAlign='center'>
              Evidence of an unauthorized fire along Rantaraitti
            </Caption>
          </figure>
        </Motion>
      </Wrapper>
      <Wrapper size='text' mb='var(--space-lg)'>
        <SectionTextWithSubtitle
          heading={subsections[1].heading}
          desc={subsections[1].desc}
          mb='var(--space-md)'
        />
      </Wrapper>
      <Wrapper size='xl'>
        <Motion variants={parentVariants}>
          <Grid col={2} gap='md'>
            <motion.div variants={fadeInUp}>
              <figure>
                <ModalImage
                  modalSize='xl'
                  path='yhteispot'
                  name='ethnography'
                  format='jpg'
                  sizes={[720, 1080, 1440]}
                  alt="Shadowing: Learn people's behavior and the problems they have"
                />
                <Caption as='figcaption'>
                  <span>Shadowing.</span> Learn people's behavior and the
                  problems they have
                </Caption>
              </figure>
            </motion.div>
            <motion.div variants={fadeInUp}>
              <figure>
                <ModalImage
                  modalSize='xl'
                  path='yhteispot'
                  name='safari'
                  format='jpg'
                  sizes={[720, 1080, 1440]}
                  alt='Service Safari: Understand what the experience is like'
                />
                <Caption as='figcaption'>
                  <span>Service Safari.</span> Understand what the experience is
                  like
                </Caption>
              </figure>
            </motion.div>
          </Grid>
        </Motion>
      </Wrapper>
    </StyledResearch>
  );
};

export default Research;
