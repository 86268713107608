import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

export const Paragraph = styled.p`
  color: inherit;
  font-family: var(--font-family-body);
  font-size: ${(props) =>
    props.level
      ? `var(--font-size-body${props.level})`
      : `var(--font-size-body2)`}};
  line-height: var(--line-height-lg);
  letter-spacing: var(--letter-spacing-xs);

  &:not(:last-of-type) {
    margin-bottom: var(--space-xs);
  }
`;

Paragraph.propTypes = {
  level: PropTypes.number,
};
