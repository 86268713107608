import React from 'react';
import { Helmet } from 'react-helmet-async';
import Demo from './Demo';
import Background from './Background';
import Research from './Research';
import Solution from './Solution';
import Prototyping from './Prototyping';
import Development from './Development';
import { FullBleedText, ProductHighlight } from 'patterns/highlight';
import { Page, Section } from 'components/container';
import Summary from 'templates/summary/Summary';
import Header from 'templates/header/Header';
import Footer from 'templates/footer/Footer';
import InpageNav from 'templates/navigation/inpage-nav/InpageNav';
import Reflection from 'templates/reflection/Reflection';
import data from 'data/volungo';

const Volungo = () => {
  const name = 'volungo';
  const {
    header,
    summary,
    demo,
    highlight,
    background,
    research,
    solution,
    prototyping,
    development,
    reflection,
  } = data;

  return (
    <Page>
      <Helmet>
        <title>VolunGO | Yuvin Ha</title>
        <meta
          name='description'
          content='VolunGO is a two-sided online platform that matches the best volunteers to the best volunteer opportunities in their cities.'
        />
      </Helmet>
      <InpageNav />
      <Header content={header} />
      <Section
        id='summary'
        color={`var(--color-${name}-text)`}
        bg={`var(--color-${name}-bg)`}
        pb='var(--space-xxl)'
      >
        <Summary
          name={name}
          content={summary}
          videoInfo={{
            id: 544449491,
            caption: 'Demonstration of the problem space and our solution',
          }}
        />
        <Demo name={name} content={demo} />
      </Section>
      <FullBleedText
        bg='var(--color-grey-600)'
        heading='How might we'
        desc={highlight.hmw}
      />
      <Section id='background'>
        <Background content={background} />
      </Section>
      <Section id='research'>
        <Research content={research} />
      </Section>
      <Section id='solution'>
        <Solution content={solution} />
      </Section>
      <Section id='prototyping'>
        <Prototyping content={prototyping} />
      </Section>
      <Section id='development' pb='var(--space-xxl)'>
        <Development content={development} />
      </Section>
      <ProductHighlight
        bg={`var(--color-${name}-bg)`}
        color={`var(--color-${name}-text)`}
        imgSrc={{
          path: 'volungo',
          name: 'product-highlight',
          format: 'png',
          sizes: [1200, 1800, 2400],
          alt: 'Product image',
        }}
        desc={highlight.product}
      />
      <Section id='reflection'>
        <Reflection content={reflection} />
      </Section>
      <Footer />
    </Page>
  );
};

export default Volungo;
