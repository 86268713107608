import React from 'react';
import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import { IoArrowForwardOutline } from 'react-icons/io5';
import StyledCallToAction from './CallToAction.styled';

const CallToAction = ({ linkTo, children, isExternal, ...props }) => {
  return (
    <StyledCallToAction as={Link} to={linkTo} {...props}>
      <span className='btnText'>{children}</span>
      <span className='btnIcon'>
        {isExternal ? <FiExternalLink /> : <IoArrowForwardOutline />}
      </span>
    </StyledCallToAction>
  );
};

export default CallToAction;
