import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { Motion, parentVariants, fadeInUp } from 'components/motion';
import { ModalImage } from 'components/image';
import { Container, Wrapper, Grid } from 'styles/layout';
import { Footnote, Caption } from 'styles/typography';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledConcept = styled(Container)`
  figure {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;

    img {
      height: 100%;
      border: 1px solid var(--color-grey-200);
    }

    figcaption {
      margin-top: var(--space-xxs);
    }
  }

  ${Footnote} {
    margin-top: var(--space-sm);
  }

  ${down(breakpoints.xs)} {
    ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

const Concept = ({ content }) => {
  const { heading, desc, reference } = content;

  return (
    <StyledConcept>
      <Wrapper size='text' mb='var(--space-lg)'>
        <SectionTitle sectionTitle='Concept development' heading={heading} />
        <SectionTextWithSubtitle desc={desc} />
        <Footnote>{reference}</Footnote>
      </Wrapper>
      <Wrapper size='xl'>
        <Motion variants={parentVariants}>
          <Grid col={3} gap='md'>
            <motion.figure variants={fadeInUp}>
              <ModalImage
                modalSize='lg'
                path='cs-expert'
                name='ideation'
                format='jpg'
                sizes={[960, 1440, 1920]}
                alt='Value/Effort matrix for idea prioritization'
              />
              <Caption as='figcaption' textAlign='center'>
                Value/Effort matrix for idea prioritization
              </Caption>
            </motion.figure>
            <motion.figure variants={fadeInUp}>
              <ModalImage
                modalSize='lg'
                path='cs-expert'
                name='sketch'
                format='jpg'
                sizes={[960, 1440, 1920]}
                alt='User scenario sketches'
              />
              <Caption as='figcaption' textAlign='center'>
                User scenario sketches
              </Caption>
            </motion.figure>
            <motion.figure variants={fadeInUp}>
              <ModalImage
                modalSize='lg'
                path='cs-expert'
                name='paper-prototype'
                format='jpg'
                sizes={[960, 1440, 1920]}
                alt='Paper prototypes'
              />
              <Caption as='figcaption' textAlign='center'>
                Paper prototypes
              </Caption>
            </motion.figure>
          </Grid>
        </Motion>
      </Wrapper>
    </StyledConcept>
  );
};

export default Concept;
