import React from 'react';
import styled from 'styled-components/macro';
import { Grid } from 'styles/layout';
import { Image } from 'components/image';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledPartners = styled(Grid)`
  filter: grayscale(1);
  opacity: 0.3;

  ${down(breakpoints.sm)} {
    gap: var(--space-lg);
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
`;

const Partners = ({ imgArray, altText, gap }) => {
  return (
    <StyledPartners col={imgArray.length} gap={gap}>
      {imgArray.map(({ path, name, format, sizes }, idx) => (
        <Image
          key={idx}
          path={path}
          name={`logo-${name}`}
          format={format}
          sizes={sizes}
          alt={altText}
        />
      ))}
    </StyledPartners>
  );
};

export default Partners;
