import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Grid, Wrapper } from 'styles/layout';
import { Motion, parentVariants, fadeInUp } from 'components/motion';
import { ModalImage } from 'components/image';
import { Caption } from 'styles/typography';

const StyledHowItWorks = styled(Wrapper)`
  figure {
    img {
      height: 100%;
      object-fit: cover;
    }

    ${Caption} {
      margin-top: var(--space-sm);
    }
  }
`;

const HowItWorks = () => {
  return (
    <StyledHowItWorks size='xl'>
      <Motion variants={parentVariants}>
        <Grid col={2} gap='lg'>
          <motion.figure variants={fadeInUp}>
            <ModalImage
              modalSize='lg'
              path='shadow-zoo'
              name='system'
              format='png'
              sizes={[960, 1440, 1920]}
              alt='Illustration of the overall structure'
            />
            <Caption as='figcaption' textAlign='center'>
              Illustration of the overall structure
            </Caption>
          </motion.figure>
          <motion.figure variants={fadeInUp}>
            <ModalImage
              modalSize='lg'
              path='shadow-zoo'
              name='detail'
              format='jpg'
              sizes={[960, 1440, 1920]}
              alt='Inside the tool box'
            />
            <Caption as='figcaption' textAlign='center'>
              Illustration of the overall structure
            </Caption>
          </motion.figure>
        </Grid>
      </Motion>
    </StyledHowItWorks>
  );
};

export default HowItWorks;
