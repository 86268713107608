import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { Allcaps, Paragraph } from 'styles/typography';
import Quote from 'patterns/Quote';
import { ModalImage } from 'components/image';
import { Motion, fadeIn, fadeInLeft } from 'components/motion';
import { Container, Wrapper } from 'styles/layout';
import { Caption } from 'styles/typography';

const StyledAnalysis = styled(Container)`
  .affinity-diagram {
    margin-bottom: var(--space-lg);
  }

  ${Caption} {
    margin-top: var(--space-xs);
  }
  .insight {
    margin-bottom: var(--space-lg);

    ${Allcaps} {
      color: var(--color-grey-500);
      margin-bottom: var(--space-xs);
    }

    & > p {
      margin-bottom: var(--space-sm);
    }
  }

  .quotes {
    padding: var(--space-md) var(--space-sm);
    background: var(--color-grey-100);
  }
`;

const parentVariants = {
  init: {
    opacity: 0,
    x: 100,
  },
  anim: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      delayChildren: 0.2,
      ease: 'easeIn',
    },
  },
};

const Research = ({ content }) => {
  const { heading, subsections, insights } = content;

  return (
    <StyledAnalysis>
      <Wrapper size='text' mb='var(--space-lg)'>
        <SectionTitle sectionTitle='analysis' heading={heading} />
        <SectionTextWithSubtitle
          heading={subsections[0].heading}
          desc={subsections[0].desc}
          mb='var(--space-md)'
        />
        <Motion variants={fadeIn}>
          <figure className='affinity-diagram'>
            <ModalImage
              modalSize='xl'
              path='yhteispot'
              name='affinity-diagram'
              format='jpg'
              sizes={[960, 1440, 1920]}
              alt='Result of affinity diagramming'
            />
            <Caption as='figcaption' textAlign='center'>
              Result of affinity diagramming
            </Caption>
          </figure>
        </Motion>
      </Wrapper>
      <Wrapper size='text' mb='var(--space-xl)'>
        {insights.map(({ category, insight, quotes }, idx) => (
          <Motion variants={parentVariants}>
            <div key={idx} className='insight'>
              <Allcaps>{category}</Allcaps>
              <Paragraph>{insight}</Paragraph>
              <motion.div className='quotes' variants={fadeIn}>
                {quotes.map(({ quote, quotedBy }, idx) => (
                  <Quote key={idx} quote={quote} quotedBy={quotedBy} />
                ))}
              </motion.div>
            </div>
          </Motion>
        ))}
      </Wrapper>
      <Wrapper size='text'>
        <SectionTextWithSubtitle
          heading={subsections[1].heading}
          desc={subsections[1].desc}
        />
      </Wrapper>
    </StyledAnalysis>
  );
};

export default Research;
