import { TAG_WELL, TAG_NEXT, TAG_CHALLENGE } from 'constant/constant';

const yhteispot = {
  header: {
    heading: 'Yhteispot',
    subheading:
      'Design a collaborative service to empower citizens for responsible leisure activities',
    hero: {
      path: 'yhteispot',
      name: 'hero',
      format: 'jpg',
      sizes: [1440, 2160, 2880],
      alt: 'yhteispot hero image',
    },
    info: {
      timeframe: 'Jan ‐ Feb 2018 (7w), Sep 2021 (1w, UX/UI Design)',
      client: 'City of Espoo',
      role: 'Research, Analysis, Ideation, Concept development, Journey mapping, Experience prototyping + Wireframes, UX/UI design',
      team: 'André Santos, Rahel Manurung, Riina Ruus-Prato, Shreya Kumar, Yuze Wang',
    },
  },
  summary: {
    challenge:
      'The city of Espoo has a 40km walkway along the waterfront that allows access to parks, beaches, and other beautiful nature views. Currently, people make a bonfire and grill in undesignated areas, which can cause fire risk and hazardous situations. Our research revealed that the sole problem was not a lack of grilling areas but the behavior of people caused by a lack of knowledge, responsibility, and feeling of community.',
    solution:
      'We proposed Yhteispot, a new collaborative service model for responsible leisure activities. It is designed to create a communal place where people gather and share their knowledge and desirable behavior, strengthening people’s feeling of responsibility and community. With two underlying systems, Local voice and Rental service points, Yhteispot enables people to understand the local context and enjoy leisure activities in a locally proper way.',
  },
  demo: {
    search: {
      heading: 'Search your next destination in Rantaraitti',
      desc: 'You can learn userful information about your next destination and discover hidden places where the locals only know.',
    },
    view: {
      heading: 'Enjoy like a local in Rantaraitti',
      desc: 'Local voices tell you the best way to behave and enjoy the area. Service points provide easy and spontaneous options for outdoor activities, which offers the perfect setting for their leisure time.',
    },
    add: {
      heading: 'Add your quote to share your knowledge',
      desc: 'You can preserve your favorite places and become part of your local community by sharing knowledge and experiences with others.',
    },
  },
  highlight: {
    brief: {
      heading: 'The original brief from the city of espoo',
      desc: '"How could we create a grilling and campfire network along Rantaraitti that receive the support from residents with NIMBY attitude, decision-makers, and civil servants?”',
    },
    hmw: {
      heading: 'Reframed project goal',
      desc: '"How might we strengthen the feeling of responsibility and community by transferring knowledge and appropriate behavior through leisure activities?"',
    },
    product: {
      heading: 'Solution',
      desc: 'Yhteispot helps people to understand the local context and enjoy leisure activities in a locally proper way.',
    },
  },
  research: {
    heading: 'Explore the problem space',
    subsections: [
      {
        heading: 'Background',
        desc: 'Even though there were clear eveidence that people were lighting a fire in Rantaraitti, the client talked to us that there are some residents with NIMBY attitudes and decision-makers who are against having a public grilling place in the area. This made us wonder what exactly Nimbys, decision-makers, and civil servants are concerned about and why.',
      },
      {
        heading: 'Feel the area & Empathize with stakeholders',
        desc: "Although we couldn't witness them ourselves since it was in the winter, we first visited different areas of Rantaraitti to understand the target area and stakeholders. We interviewed 15 people—visitors and business owners—and collected 31 opinions from the local Facebook group. We also contacted several civil servants to ask about the problems facing them. In addition, we carried out ethnographic research by going to a national park with two Finnish families and observing how Finnish people enjoy grilling outside and what the experience is like.",
      },
    ],
  },
  analysis: {
    heading: 'Define the right problem to solve',
    subsections: [
      {
        heading: 'Synthesize the research findings',
        desc: 'We synthesized all findings collected from the research through affinity diagramming. As a result, we developed eight themes, and among them, four—Responsibility, Maintenance, Sense of community, and Range of services—drew our attention because people seem to fear vandalism as a consequence of the lack of a sense of community, responsibility, and maintenance.',
      },
      {
        heading: 'Reframe the brief',
        desc: 'We found out that the sole problem was not a lack of grilling areas but rather the behavior of people caused by a lack of knowledge, responsibility, and feeling of community. Therefore, we reframed the brief: "We are not designing grilling places. We use grilling as a leverage point to nudge other recreational activities.”',
      },
    ],
    insights: [
      {
        category: 'Responsibility',
        insight:
          'It is easy for people to shift responsibility to somebody else in public spaces without feeling responsible for common areas. Also, the locals think people from outside don’t know what to do and how to behave in the area.',
        quotes: [
          {
            quote: 'If we grill at home, we know we have to clean it.',
            quotedBy: 'University student',
          },
          {
            quote: 'Foreigners and immigrants are making a mess.',
            quotedBy: "Response from the city's survey",
          },
        ],
      },
      {
        category: 'Sense of Community',
        insight:
          'People have a lot of knowhow and are willing to share it with others if given the opportunity. Also, residents know the area best and they have a lot of knowledge that they enjoy sharing.',
        quotes: [
          {
            quote:
              'A grilling place would be appreciated not only amongst the youngsters but us oldies as well.',
            quotedBy: 'Facebook group comment',
          },
          {
            quote:
              'The husband uses a knife to sharpen the end of the branches for making skewers.',
            quotedBy: 'Observation',
          },
        ],
      },
      {
        category: 'Range of Services & Maintenance',
        insight:
          'People feel that there are not enough leisure options in Rantaraitti and wish to have more in the area. However, due to the lack of budget and human resources, the city needs civic participation to maintain services in the area.',
        quotes: [
          {
            quote:
              'A sauna would be something a lot of people have wanted for a long time.',
            quotedBy: 'Owner of Café',
          },
          {
            quote:
              'Nobody takes full responsibility for the whole area. [...] Need to find a way to maintain without much cost.',
            quotedBy: 'Civil Servant of the city',
          },
        ],
      },
    ],
  },
  concept: {
    heading: 'Develop design concepts',
    subsections: [
      {
        heading: 'Generate ideas',
        desc: `We defined the design drivers to achieve our new goal: 1) Make people feel a sense of responsibility by showing them the best way to act, 2) Give people the power to build common spaces together to share knowledge and experiences with others, 3) Provide easy and spontaneous options for leisure activities, sharing the responsibility of maintenance with user.
        With the reframed project goal and the design drivers, we generated a total of 30 initial ideas, and then developed 12 design concepts by combining and expanding ideas.`,
      },
      {
        heading: 'Evaluate the concepts',
        desc: 'We evaluated the concepts based on the criteria—values for stakeholders (e.g. users, city and service owners), effort, and cost—with more weight on values. After that, we presented the top five ideas to the client and received feedback from the city perspective. Based on the comments, we narrowed them down to the two most potential concepts and developed our final design solution.',
      },
    ],
  },
  solution: {
    heading: '',
    subsections: [
      {
        heading:
          'A collaborative service model for responsible leisure activities',
        desc: 'We proposed a collaborative service model named Yhteispot (this coined term would translate to “Together spot”). With two underlying systems, Local voice and Rental service points, Yhteispot enables people to understand the local context and enjoy leisure.',
      },
      {
        heading: 'User journeys',
        desc: 'The User journey map below shows how various stakeholders engage with Yhteispot. Each of the four types of users is expected to enjoy the area in different ways using Yhteispot.',
        story:
          'The Spontaneous can have more opportunities for outdoor activities since they easily rent tools on the spot. Planners are allowed to learn useful information about the place, which offers the perfect setting for their leisure time. Although Passerby and Sheriff do not actively take part in activities, they become more engaged in their local area. The sheriff share their knowledge with others via the online platform to preserve his favorite places.',
      },
    ],
    features: {
      localVoice: {
        heading: 'Local voice, a knowledge transferring system',
        desc: 'People share their knowledge and behavior through Local voice. An online tool helps to collect quotes, and selected ones are installed by the city as a public sign.',
      },
      servicePoint: {
        heading: 'Rental service point, a hub of leisure activities',
        desc: 'Rental service points are run by local entrepreneurs or service owners in collaboration with the city, where people can rent tools for various activities.',
      },
    },
  },
  prototyping: {
    heading: 'UX/UI Design for progressive web application',
    desc: 'After the course, I further worked on UX/UI design for the Yhteispot online platform. I created a wireframe and high-fidelity prototype for four major user scenarios:',
    scenarios: [
      'Visiting the home page on desktop/mobile',
      'Searching the location and browsing the results',
      'Viewing the detail page of Local voices and Service points',
      'Adding a new quote on a specific location in the map',
    ],
  },
  reflection: [
    {
      heading: '',
      desc: 'Since the winter is not the best season for grilling, it was hard to find people grilling in the Rantaraitti area. We could have had the wider engagement with relevant stakeholders and developed a more holistic understanding if we had done this project during the summer.',
      tags: [TAG_CHALLENGE],
    },
    {
      heading: '',
      desc: 'As a workaround, we visited to a local cafe where customers can grill the sausages outside all year around and interviewed a few customers. We also recruited two Finnish families and observed them grilling in a public park. This still helped us to empathize with actual users and understand the concerns that the stakeholders might have.',
      tags: [TAG_WELL],
    },
    {
      heading: '',
      desc: 'I learned once again the importance of problem definition and creating the right brief to answer. By shifting our focus from the lack of proper places to people’s behavioural patterns based on the research, we were able to offer a critical perspective of the challenge and connect the grilling issue into the wider vision of the Rantaraitti area.',
      tags: [TAG_WELL],
    },
    {
      heading: '',
      desc: 'We rapidly prototyped the service experience by taking a video in the actual place to show what it could look like and how it could work. However, unfortunately, we were not able to validate our solution due to the limited time. Next steps will be testing the interventions on a small scale with actual users in real context.',
      tags: [TAG_NEXT],
    },
  ],
};

export default yhteispot;
