import React from 'react';
import styled from 'styled-components/macro';
import { Subtitle } from 'styles/typography';
import { Grid } from 'styles/layout';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledProjectInfo = styled(Grid)`
  margin-top: var(--space-lg);
  list-style: none;

  ${Subtitle} {
    margin-bottom: var(--space-xxs);
  }

  ${down(breakpoints.sm)} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${down(breakpoints.xs)} {
    grid-template-columns: 1fr;
  }
`;

const ProjectInfo = ({ info }) => {
  const keys = Object.keys(info);

  const infoList = keys.map((key) => (
    <li key={key}>
      <Subtitle level={3}>
        {key.charAt(0).toUpperCase() + key.slice(1)}
      </Subtitle>
      {info[key]}
    </li>
  ));

  return (
    <StyledProjectInfo as='ul' col={['1fr', '1fr', '2fr', '2fr']} gap='md'>
      {infoList}
    </StyledProjectInfo>
  );
};

export default ProjectInfo;
