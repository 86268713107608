import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$column ? 'column' : 'row')};
  flex-wrap: ${(props) => (props.$wrap ? 'wrap' : 'nowrap')};
  gap: ${(props) => (props.gap ? `var(--space-${props.gap})` : 0)};
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
`;

Flex.propTypes = {
  gap: PropTypes.string,
  $column: PropTypes.string,
  $wrap: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
};
