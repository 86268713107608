import styled from 'styled-components/macro';

export const Allcaps = styled.div`
  font-family: var(--font-family-title);
  font-size: var(--font-size-allcaps);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-md);
  letter-spacing: var(--letter-spacing-lg);
  text-transform: uppercase;
`;
