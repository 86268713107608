import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Allcaps, Headline } from 'styles/typography';
import { Wrapper } from 'styles/layout';
import spacer from 'utils/spacer';

const StyledSectionTitle = styled(Wrapper)`
  margin-bottom: var(--space-md);

  & > ${Allcaps} {
    opacity: 0.7;
    margin-bottom: var(--space-xs);
  }

  & > ${Headline} {
    margin-bottom: var(--space-md);
  }

  ${spacer}
`;

const SectionTitle = (props) => {
  return (
    <StyledSectionTitle size='text' {...props}>
      {props.sectionTitle && <Allcaps>{props.sectionTitle}</Allcaps>}
      {props.heading && (
        <Headline as='h3' level={4}>
          {props.heading}
        </Headline>
      )}
    </StyledSectionTitle>
  );
};

SectionTitle.propTypes = {
  sectionTitle: PropTypes.string,
  heading: PropTypes.string.isRequired,
};

export default SectionTitle;

// {
//   desc && <Paragraph>{desc}</Paragraph>;
// }
// {
//   subsections &&
//     subsections.map((subsection, idx) => (
//       <SubSectionTextWithSubtitle key={idx} content={subsection} />
//     ));
// }
