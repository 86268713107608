import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import aligner from 'utils/aligner';

export const Caption = styled.div`
  color: ${(props) => (props.color ? props.color : 'var(--color-grey-500)')};
  font-family: var(--font-family-body);
  font-size: var(--font-size-caption);
  line-height: var(--line-height-md);
  letter-spacing: var(--letter-spacing-sm);

  ${aligner};
`;

export const Footnote = styled(Caption)`
  &::before {
    content: '';
    display: block;
    width: 50%;
    height: 1px;
    background: var(--color-grey-300);
    margin-bottom: var(--space-xs);
  }
`;

Caption.propTypes = {
  color: PropTypes.string,
};
