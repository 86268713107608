const umbrella = {
  header: {
    heading: 'Umbrella Forecaster',
    subheading: 'Interactive, physical data visualization of a chance of rain',
    hero: {
      path: 'umbrella',
      name: 'cover',
      videoId: 435364906,
      sizes: [1440, 2160, 2880],
    },
    info: {
      timeframe: 'Jan – Feb 2019 (6w)',
      tools: 'Arduino, Processing, Autodesk Fusion 360',
      role: 'Project concept, Physical computing, Software development, Fabrication, Visual design',
      team: 'Individual project (tutored by Matti Niinimäki)',
    },
  },
  summary: {
    description: {
      heading: 'Project description',
      desc: 'Umbrella forecaster visualizes a chance of rain at a particular location by opening and closing an umbrella accordingly. You can enter the name of any cities in the world and intuitively understand the probability of rain by looking at how widely the umbrella is open.',
    },
    howItWorks: {
      heading: 'How it works',
      desc: 'Two APIs were used to fetch the real-time weather forecast data at a particular location. Then, the value of the probability of precipitation is transformed into value in degrees and transmitted to Arduino. Finally, a servo motor controls its shaft position using the data passed.',
    },
  },
};

export default umbrella;
