import React from 'react';
import styled from 'styled-components/macro';
import ProjectInfo from './ProjectInfo';
import useInpageNav from 'hooks/useInpageNav';
import { Image } from 'components/image';
import { ReactVimeoVideo } from 'components/video';
import { Headline, Paragraph } from 'styles/typography';
import { Container } from 'styles/layout';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledHeader = styled.header`
  margin: var(--space-xxl) 0;

  .heading {
    text-align: center;
    margin-bottom: var(--space-xl);

    ${Headline} {
      margin-bottom: var(--space-sm);
    }

    ${Paragraph} {
      color: var(--color-grey-600);
    }
  }

  img {
    height: 75vh;
    border-radius: var(--radius-sm);
    object-position: center;
  }

  ${down(breakpoints.sm)} {
    img {
      height: 56.25vw;
    }
  }
`;

const Header = ({ content: { heading, subheading, hero, info } }) => {
  const ref = useInpageNav('top');

  return (
    <StyledHeader ref={ref} id='top'>
      <Container>
        <div className='heading'>
          <Headline level={2}>{heading}</Headline>
          <Paragraph level={1}>{subheading}</Paragraph>
        </div>
        {hero.name === 'cover' ? (
          <ReactVimeoVideo
            videoId={hero.videoId}
            cover={{
              path: hero.path,
              name: 'cover',
              size: 2880,
            }}
          />
        ) : (
          <Image
            path={hero.path}
            name={hero.name}
            format={hero.format}
            sizes={hero.sizes}
            alt={hero.alt}
          />
        )}
        <ProjectInfo info={info} />
      </Container>
    </StyledHeader>
  );
};

export default Header;
