import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

const StyledPage = styled(motion.div)`
  width: 100vw;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;

  & > div {
    position: relative;
  }
`;

const pageVariants = {
  init: {
    y: '100vh',
  },
  anim: {
    y: 0,
    transition: {
      type: 'tween',
      duration: 1,
      ease: [0.85, 0, 0.15, 1],
    },
  },
  exit: {
    y: '-100vh',
    transition: {
      type: 'tween',
      duration: 1,
      ease: [0.85, 0, 0.15, 1],
    },
  },
};

const Page = ({ children }) => {
  const ref = useRef(null);

  const handleStart = () => {
    requestAnimationFrame(() => {
      if (ref.current) {
        // Adjust visible area in the frame
        ref.current.children[0].style.top = -window.scrollY + 'px';

        // Cut the page by the visible area
        Object.assign(ref.current.style, {
          height: '100vh',
          overflow: 'hidden',
          position: 'absolute',
        });

        // Set scroll to the top of the window
        window.scroll({ top: 0 });
      }
    });
  };

  const handleComplete = () => {
    requestAnimationFrame(() => {
      if (ref.current) ref.current.removeAttribute('style');
    });
  };

  return (
    <StyledPage
      ref={ref}
      variants={pageVariants}
      initial='init'
      animate='anim'
      exit='exit'
      onAnimationStart={handleStart}
      onAnimationComplete={handleComplete}
    >
      <div>{children}</div>
    </StyledPage>
  );
};

export default Page;
