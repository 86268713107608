import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { Motion, parentVariants, fadeInUp, fadeIn } from 'components/motion';
import { ModalImage } from 'components/image';
import { Container, Wrapper, Grid } from 'styles/layout';
import { Caption } from 'styles/typography';

const StyledConcept = styled(Container)`
  .sketch {
    img {
      border: 1px solid var(--color-grey-200);
    }

    ${Caption} {
      margin-top: var(--space-xs);
    }
  }
`;

const Concept = ({ content }) => {
  const { heading, subsections } = content;

  return (
    <StyledConcept>
      <SectionTitle sectionTitle='Concept Development' heading={heading} />
      <Wrapper size='xl' mb='var(--space-lg)'>
        <SectionTextWithSubtitle
          heading={subsections[0].heading}
          desc={subsections[0].desc}
          mb='var(--space-md)'
        />
        <Motion variants={parentVariants}>
          <Grid col={2} gap='md'>
            <motion.figure className='sketch' variants={fadeInUp}>
              <ModalImage
                modalSize='lg'
                path='yhteispot'
                name='sketch-voice'
                format='jpg'
                sizes={[600, 900, 1200]}
                alt='Initial concept of Local voice'
              />
              <Caption as='figcaption' textAlign='center'>
                Initial concept of Local voice
              </Caption>
            </motion.figure>
            <motion.figure className='sketch' variants={fadeInUp}>
              <ModalImage
                modalSize='lg'
                path='yhteispot'
                name='sketch-rental'
                format='jpg'
                sizes={[600, 900, 1200]}
                alt='Initial concept of Rental service point'
              />
              <Caption as='figcaption' textAlign='center'>
                Initial concept of Rental service point
              </Caption>
            </motion.figure>
          </Grid>
        </Motion>
      </Wrapper>
      <Wrapper size='text'>
        <SectionTextWithSubtitle
          heading={subsections[1].heading}
          desc={subsections[1].desc}
          mb='var(--space-md)'
        />
        <Motion variants={fadeIn}>
          <figure className='sketch'>
            <ModalImage
              modalSize='xl'
              path='yhteispot'
              name='evaluation'
              format='jpg'
              sizes={[800, 1200, 1600]}
              alt='Result of the concept evaluation'
            />
            <Caption as='figcaption' textAlign='center'>
              Result of the concept evaluation
            </Caption>
          </figure>
        </Motion>
      </Wrapper>
    </StyledConcept>
  );
};

export default Concept;
