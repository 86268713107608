const heartbeat = {
  header: {
    heading: 'Your Heartbeat',
    subheading:
      'Interactive art where you feel your heartbeat with eyes and ears',
    hero: {
      path: 'heartbeat',
      name: 'cover',
      videoId: 398938111,
      sizes: [1440, 2160, 2880],
    },
    info: {
      timeframe: 'November 2018 (3w)',
      tools: 'Bela, Pure Data, Autodesk Fusion 360',
      role: 'Project concept, Physical computing, Software development, Fabrication',
      team: 'Individual project (tutored by Koray Tahiroglu and J. Camilo Sanchez Carranco)',
    },
  },
  summary: {
    description: {
      heading: 'Project description',
      desc: 'This interactive installation enables you to see and listen to your every heartbeat. The rhythmic movements of your heart is manifested through the inflation and deflation of a plastic bag, where air signifies blood that flow through the heart while a plastic bag implies the organ itself. Surrounded by the sound of your beating heart, you can see every single pound in your chest and feel alive.',
    },
    howItWorks: {
      heading: 'How it works',
      desc: 'Synchronized with the contraction and relaxation of the heart, one fan blows air into a plastic bag while the other one evacuates the air from it. The heartbeat sound picked up through a piezoelectric sensor is amplified by an electronic stethoscope and processed by a program developed with Pure Data.',
    },
  },
};

export default heartbeat;
