import React from 'react';
import { Helmet } from 'react-helmet-async';
import Featured from './featured/Featured';
import MoreWorks from './moreworks/MoreWorks';
import Page from 'components/container/Page';
import Footer from 'templates/footer/Footer';
import Hero from 'templates/hero/Hero';
import { Container } from 'styles/layout';
import { works } from 'data/common';

const Home = () => {
  return (
    <Page>
      <Helmet>
        <title>Works | Yuvin Ha</title>
        <meta
          name='description'
          content='Yuvin Ha is a full-stack designer who researches, designs, and codes to create user-centered digital products.'
        />
      </Helmet>
      <Container>
        <Hero
          sectionTitle='Hi, there! 👋'
          headline='I design products and craft interactions aiming to add value and beauty to our life'
          // headline='I design products and craft interactions to enrich our lives and enhance the way people interact with the world '
          // headline='I design products and craft interactions to empower people and enhance the way they interact with the world '
          linkTo='about'
          btnText='About me'
          pb={11}
        />
        <Featured works={works.featured} />
        <MoreWorks works={works.moreworks} />
      </Container>
      <Footer />
    </Page>
  );
};

export default Home;
