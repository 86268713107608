import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { SectionTitle, SectionTextWithSubtitle } from 'patterns/section';
import { Motion, parentVariants, fadeIn, fadeInUp } from 'components/motion';
import { ModalImage } from 'components/image';
import { Container, Wrapper, Grid } from 'styles/layout';
import Insight from 'patterns/Insight';
import theme from 'styles/theme';

const { down, breakpoints } = theme;

const StyledAnalysis = styled(Container)`
  .insights {
    height: 100%;
  }

  ${down(breakpoints.xs)} {
    ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

const Analysis = ({ content }) => {
  const { heading, subsections, insights } = content;

  return (
    <StyledAnalysis>
      <Wrapper size='text' mb='var(--space-xl)'>
        <SectionTitle sectionTitle='Analysis' heading={heading} />
        <SectionTextWithSubtitle
          heading={subsections[0].heading}
          desc={subsections[0].desc}
          mb='var(--space-lg)'
        />
        <Motion variants={fadeIn}>
          <ModalImage
            modalSize='xl'
            path='cs-expert'
            name='xgoal-matrix'
            format='png'
            sizes={[1200, 1800, 2400]}
            alt='Xgoal matrix'
          />
        </Motion>
      </Wrapper>
      <Wrapper size='text' mb='var(--space-xl)'>
        <SectionTextWithSubtitle
          heading={subsections[1].heading}
          desc={subsections[1].desc}
          mb='var(--space-md)'
        />
        <Motion variants={fadeIn}>
          <ModalImage
            modalSize='xl'
            path='cs-expert'
            name='affinity-diagram'
            format='jpg'
            sizes={[960, 1440, 1920]}
            alt='Result of Affinity diagramming'
          />
        </Motion>
      </Wrapper>
      <Wrapper size='xl'>
        <SectionTextWithSubtitle
          heading={subsections[2].heading}
          desc={subsections[2].desc}
          mb='var(--space-md)'
        />
        <Motion variants={parentVariants}>
          <Grid col={2} gap='md'>
            <motion.div variants={fadeInUp}>
              <Insight
                className='insights'
                keyword='Self-actualization'
                content={insights['self-actualization']}
              />
            </motion.div>
            <motion.div variants={fadeInUp}>
              <Insight
                className='insights'
                keyword='Developemnt &amp; Learning'
                content={insights['development-learning']}
              />
            </motion.div>
          </Grid>
        </Motion>
      </Wrapper>
    </StyledAnalysis>
  );
};

export default Analysis;
